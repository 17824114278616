import _typeof from "D:/wwwroot/\u6709\u53F0\u79D1\u6280\u4F9B\u5E94\u94FE\u534F\u540C\u5E73\u53F0/node_modules/@babel/runtime/helpers/esm/typeof";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.to-string.js";
import dayjs from 'dayjs';
var format = {};

format.isDate = function () {
  var sdate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var dateFormat = /^(\d{4})-(\d{2})-(\d{2})$/;

  if (sdate.length === 10) {
    if (dateFormat.test(sdate)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

format.date = function () {
  var sDate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var reg = /^\d{4}-\d{1,2}-\d{1,2}$/;
  var reg2 = /^\d{4}\/\d{1,2}\/\d{1,2}$/;
  var reg3 = /^\d{4}\d{2}\d{2}$/;
  var reg4 = /^\d{4}\.\d{1,2}\.\d{1,2}$/;

  if (sDate) {
    sDate = sDate.replace(/\./g, '-');
    sDate = sDate.replace(/\//g, '-');

    if (reg.test(sDate) || reg2.test(sDate) || reg3.test(sDate) || reg4.test(sDate)) {
      sDate = dayjs(sDate).format('YYYY-MM-DD');

      if (sDate.length === 8 && !isNaN(sDate)) {
        sDate = sDate.substr(0, 4) + '-' + sDate.substr(4, 2) + '-' + sDate.substr(6, 2);
      }
    } else {
      sDate = '';
    }
  }

  return sDate;
};

format.jsort = function (array, field, reverse) {
  // 数组长度小于2 或 没有指定排序字段 或 不是json格式数据
  if (array.length < 2 || !field || _typeof(array[0]) !== 'object') return array; // 数字类型排序

  if (typeof array[0][field] === 'number') {
    array.sort(function (x, y) {
      return x[field] - y[field];
    });
  } // 字符串类型排序


  if (typeof array[0][field] === 'string') {
    array.sort(function (x, y) {
      return x[field].localeCompare(y[field]);
    });
  } // 判断是否需要倒序


  if (reverse) {
    array.reverse();
  }

  return array;
};

format.number = function (val, arg) {
  val = val.replace(/[^\d^.-]+/g, '').replace(/^\./g, '').replace(/^(-)|-+/g, '$1').replace(/^([^.]*\.)|\.+/g, '$1');

  if (val && val !== '-') {
    if (arg) {
      if (arg.hasOwnProperty('min')) {
        if (Number(val) < Number(arg.min)) {
          val = arg.min;
        }
      }

      if (arg.hasOwnProperty('max')) {
        if (Number(val) > Number(arg.max)) {
          val = arg.max;
        }
      }

      if (arg.hasOwnProperty('precision')) {
        if (parseInt(arg.precision) === 0) {
          val = parseInt(val);
        } else {
          var reg = new RegExp('^(\\d{10})\\d+|((\\.\\d{' + arg.precision + '})\\d+)$', 'g');
          val = val.replace(reg, '$1$3');
        }
      } else {
        var _reg = new RegExp('^(\\d{10})\\d+|((\\.\\d{2)\\d+)$', 'g');

        val = val.replace(_reg, '$1$3');
      }
    }
  }

  return val;
};

format.arraySort = function (objArr, keyArr, type) {
  for (var x = 0; x < objArr.length; x++) {
    objArr[x].oldIndex = x;
  }

  if (type !== undefined && type !== 'asc' && type !== 'desc') {
    return 'error';
  }

  var order = 1;

  if (type !== undefined && type === 'desc') {
    order = -1;
  }

  objArr.sort(function (objA, objB) {
    var key = keyArr[0];

    if (objA[key] > objB[key]) {
      return order;
    } else if (objA[key] < objB[key]) {
      return 0 - order;
    } else {
      if (objA.oldIndex > objB.oldIndex) {
        return order;
      } else if (objA.oldIndex < objB.oldIndex) {
        return 0 - order;
      } else {
        return 0;
      }
    }
  });

  var _loop = function _loop() {
    var key = keyArr[i];

    for (y = 0; y < objArr.length; y++) {
      objArr[y].oldIndex = y;
    }

    objArr.sort(function (objA, objB) {
      for (var j = 0; j < i; j++) {
        if (objA[keyArr[j]] !== objB[keyArr[j]]) {
          if (objA.oldIndex > objB.oldIndex) {
            return order;
          } else if (objA.oldIndex < objB.oldIndex) {
            return 0 - order;
          } else {
            return 0;
          }
        }
      }

      if (objA[key] > objB[key]) {
        return order;
      } else if (objA[key] < objB[key]) {
        return 0 - order;
      } else {
        if (objA.oldIndex > objB.oldIndex) {
          return order;
        } else if (objA.oldIndex < objB.oldIndex) {
          return 0 - order;
        } else {
          return 0;
        }
      }
    });
  };

  for (var i = 1; i < keyArr.length; i++) {
    var y;

    _loop();
  }

  return objArr;
};

export default format;