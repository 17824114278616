import { request } from '@/api/_service.js';
export function listAgentSellOrder(params) {
  return request({
    url: '/agentSellOrderErpNew/getAgentSellOrderList',
    method: 'get',
    params: params
  });
}
export function listAgentSellOrderExport(params) {
  return request({
    url: '/agentSellOrderErpNew/getAgentSellOrderList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findAgentSellOrder(params) {
  return request({
    url: '/agentSellOrderErpNew/findAgentSellOrder',
    method: 'get',
    params: params
  });
}
export function createAgentSellOrder(data) {
  return request({
    url: '/agentSellOrderErpNew/createAgentSellOrder',
    method: 'post',
    data: data
  });
}
export function updateAgentSellOrder(data) {
  return request({
    url: '/agentSellOrderErpNew/updateAgentSellOrder',
    method: 'put',
    data: data
  });
}
export function updateAgentSellOrderState(data) {
  return request({
    url: '/agentSellOrderErpNew/updateAgentSellOrderState',
    method: 'put',
    data: data
  });
}
export function deleteAgentSellOrder(data) {
  return request({
    url: '/agentSellOrderErpNew/deleteAgentSellOrder',
    method: 'delete',
    data: data
  });
}
export function listAgentSellOrderDetail(params) {
  return request({
    url: '/agentSellOrderDetailErpNew/getAgentSellOrderDetailList',
    method: 'get',
    params: params
  });
}
export function createAgentSellOrderDetails(data) {
  return request({
    url: '/agentSellOrderDetailErpNew/createAgentSellOrderDetails',
    method: 'post',
    data: data
  });
}
export function updateAgentSellOrderDetail(data) {
  return request({
    url: '/agentSellOrderDetailErpNew/updateAgentSellOrderDetail',
    method: 'put',
    data: data
  });
}
export function findAgentSellOrderDetail(params) {
  return request({
    url: '/agentSellOrderDetailErpNew/findAgentSellOrderDetail',
    method: 'get',
    params: params
  });
}
export function deleteAgentSellOrderDetail(data) {
  return request({
    url: '/agentSellOrderDetailErpNew/deleteAgentSellOrderDetail',
    method: 'delete',
    data: data
  });
}
export function downloadConsignTemplate(params) {
  return request({
    url: '/agentSellOrderDetailErpNew/getAgentSellOrderDetailTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function downloadSellTemplate(params) {
  return request({
    url: '/sellOrderDetailErpNew/getSellOrderDetailTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function updateStockoutStatus(data) {
  return request({
    url: '/stockDealer/updateStockoutStatus',
    method: 'put',
    data: data
  });
}
/**
 * 获取销售订单列表
 *
 * @param params 请求参数
 */

export function listSellOrder(params) {
  return request({
    url: '/sellOrderErpNew/getSellOrderList',
    method: 'get',
    params: params
  });
}
/**
 * 导出销售订单列表
 *
 * @param params 请求参数对象
 * @returns 返回请求的结果
 */

export function listSellOrderExport(params) {
  return request({
    url: '/sellOrderErpNew/getSellOrderList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
/**
 * 查询销售订单详情
 *
 * @param params 查询参数
 * @returns {Promise<any>} 返回查询结果的Promise对象
 */

export function findSellOrder(params) {
  return request({
    url: '/sellOrderErpNew/findSellOrder',
    method: 'get',
    params: params
  });
}
/**
 * 创建销售订单
 *
 * @param {Object} data - 包含订单信息的对象
 * @returns {Promise<any>} - 异步返回创建销售订单的结果
 */

export function createSellOrder(data) {
  return request({
    url: '/sellOrderErpNew/createSellOrder',
    method: 'post',
    data: data
  });
}
/**
 * 更新销售订单
 *
 * @param data 需要更新的销售订单数据
 * @returns 请求结果
 */

export function updateSellOrder(data) {
  return request({
    url: '/sellOrderErpNew/updateSellOrder',
    method: 'put',
    data: data
  });
}
/**
 * 更新销售订单状态
 *
 * @param {Object} data - 请求参数对象
 * @returns {Promise} 返回Promise对象，resolve时返回请求结果
 */

export function updateSellOrderState(data) {
  return request({
    url: '/sellOrderErpNew/updateSellOrderState',
    method: 'put',
    data: data
  });
}
/**
 * 删除销售订单
 *
 * @param {Object} data - 请求参数对象
 * @returns {Promise<any>} - 返回一个Promise对象，用于处理异步请求的结果
 */

export function deleteSellOrder(data) {
  return request({
    url: '/sellOrderErpNew/deleteSellOrder',
    method: 'delete',
    data: data
  });
}
/**
 * 获取销售订单详情列表
 *
 * @param {Object} params 请求参数
 * @returns {Promise} 请求结果
 */

export function listSellOrderDetail(params) {
  return request({
    url: '/sellOrderDetailErpNew/getSellOrderDetailList',
    method: 'get',
    params: params
  });
}
/**
 * 创建销售订单详情
 *
 * @param {Object} data - 请求参数对象
 * @returns {Promise} 请求的 Promise 对象，包含响应结果
 */

export function createSellOrderDetails(data) {
  return request({
    url: '/sellOrderDetailErpNew/createSellOrderDetails',
    method: 'post',
    data: data
  });
}
export function updateSellOrderDetail(data) {
  return request({
    url: '/sellOrderDetailErpNew/updateSellOrderDetail',
    method: 'put',
    data: data
  });
}
/**
 * 删除销售订单详情
 *
 * @param {Object} data - 请求参数对象
 * @returns {Promise<any>} - 请求结果
 */

export function deleteSellOrderDetail(data) {
  return request({
    url: '/sellOrderDetailErpNew/deleteSellOrderDetail',
    method: 'delete',
    data: data
  });
}