import { request } from '@/api/_service.js';
export function listStockMain(params) {
  return request({
    url: '/stockNew/getStockMainList',
    method: 'get',
    params: params
  });
}
export function listStockMainExport(params) {
  return request({
    url: '/stockNew/getStockMainList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function listStockOutOrder(params) {
  return request({
    url: '/stockDealer/getStockoutList',
    method: 'get',
    params: params
  });
}
export function listStockDetail(params) {
  return request({
    url: '/stockDealer/getStockoutInfoList',
    method: 'get',
    params: params
  });
}