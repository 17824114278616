import { request } from '@/api/_service.js';
var setting = {
  uploadApiURL: '/insideNew/upload',
  // 上传接口
  removeApiURL: '/inside/remove' // 删除接口

};
export var upload = {
  get: function get(t) {
    return setting[t];
  }
};
export function downloadFile(url, params) {
  return request({
    url: url,
    method: params ? 'post' : 'get',
    params: params,
    responseType: 'blob'
  });
}
export function uploadFile(data) {
  return request({
    url: '/insideNew/upload',
    method: 'post',
    data: data,
    responseType: 'blob'
  });
}
export function listCategory() {
  return request({
    url: '/inside/GetCategoryList',
    method: 'get'
  });
}
export function sendCode(data) {
  return request({
    url: '/erp/sms_send',
    method: 'post',
    data: data
  });
}
export function updatePassword(data) {
  return request({
    url: '/erp/dealer_user_update_password',
    method: 'post',
    data: data
  });
}
/** ERP后台菜单列表接口 **/

export function listMenu() {
  return request({
    url: '/erp/menu?t=' + Date.now(),
    method: 'get'
  });
}