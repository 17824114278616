var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        visible: _vm.drawerVisible,
        direction: "rtl",
        size: "70%",
        "before-close": _vm.drawerClose
      },
      on: {
        "update:visible": function($event) {
          _vm.drawerVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            height: "100%",
            display: "flex",
            "flex-direction": "column",
            "justify-content": "space-between",
            padding: "0 20px 20px 20px"
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "stockForm",
                      attrs: {
                        inline: true,
                        "label-width": "80px",
                        size: "mini",
                        "label-suffix": ":"
                      },
                      nativeOn: {
                        submit: function($event) {
                          $event.preventDefault()
                        },
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.stockFormSubmit.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.stockForm,
                        callback: function($$v) {
                          _vm.stockForm = $$v
                        },
                        expression: "stockForm"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品主码" } },
                        [
                          _c("el-input", {
                            staticClass: "bar-input",
                            attrs: { placeholder: "产品主码", clearable: "" },
                            model: {
                              value: _vm.stockForm.barCode,
                              callback: function($$v) {
                                _vm.$set(_vm.stockForm, "barCode", $$v)
                              },
                              expression: "stockForm.barCode"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.stockForm.barCode
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "产品副码" } },
                            [
                              _c("el-input", {
                                ref: "barcodeInput",
                                staticClass: "bar-input",
                                attrs: {
                                  placeholder: "产品副码",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.scanBarCode,
                                  callback: function($$v) {
                                    _vm.scanBarCode = $$v
                                  },
                                  expression: "scanBarCode"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品编号" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "产品编号", clearable: "" },
                            model: {
                              value: _vm.stockForm.productNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.stockForm, "productNumber", $$v)
                              },
                              expression: "stockForm.productNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "物资名称" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "物资名称", clearable: "" },
                            model: {
                              value: _vm.stockForm.productName,
                              callback: function($$v) {
                                _vm.$set(_vm.stockForm, "productName", $$v)
                              },
                              expression: "stockForm.productName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchFormExpand,
                              expression: "searchFormExpand"
                            }
                          ],
                          attrs: { label: "规格型号" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "规格型号", clearable: "" },
                            model: {
                              value: _vm.stockForm.specificationModel,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.stockForm,
                                  "specificationModel",
                                  $$v
                                )
                              },
                              expression: "stockForm.specificationModel"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchFormExpand,
                              expression: "searchFormExpand"
                            }
                          ],
                          attrs: { label: "品牌" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择品牌",
                                clearable: ""
                              },
                              model: {
                                value: _vm.stockForm.brandId,
                                callback: function($$v) {
                                  _vm.$set(_vm.stockForm, "brandId", $$v)
                                },
                                expression: "stockForm.brandId"
                              }
                            },
                            _vm._l(_vm.brandList, function(item) {
                              return _c("el-option", {
                                key: item.name,
                                attrs: { label: item.name, value: item.ID }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchFormExpand,
                              expression: "searchFormExpand"
                            }
                          ],
                          attrs: { label: "生产批号" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "生产批号", clearable: "" },
                            model: {
                              value: _vm.stockForm.batch_number,
                              callback: function($$v) {
                                _vm.$set(_vm.stockForm, "batch_number", $$v)
                              },
                              expression: "stockForm.batch_number"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchFormExpand,
                              expression: "searchFormExpand"
                            }
                          ],
                          attrs: { label: "序列号" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "序列号", clearable: "" },
                            model: {
                              value: _vm.stockForm.trackingCode,
                              callback: function($$v) {
                                _vm.$set(_vm.stockForm, "trackingCode", $$v)
                              },
                              expression: "stockForm.trackingCode"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", "label-width": "0" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-right": "20px" },
                              attrs: { type: "primary" },
                              on: { click: _vm.stockFormSubmit }
                            },
                            [_vm._v("查 询")]
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary", underline: false },
                              on: {
                                click: function($event) {
                                  _vm.searchFormExpand = !_vm.searchFormExpand
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.searchFormExpand ? "合并" : "展开")
                              ),
                              _c("i", {
                                class:
                                  "el-icon-arrow-" +
                                  (_vm.searchFormExpand ? "up" : "down") +
                                  " el-icon--right"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  ref: "detailListTable",
                  attrs: {
                    data: _vm.detailList,
                    border: "",
                    "highlight-current-row": "",
                    size: "mini",
                    "header-cell-style": {
                      background: "#f5f7fa",
                      borderColor: "#ebeef5",
                      color: "#333"
                    },
                    "row-class-name": _vm.rowClassName
                  },
                  on: {
                    "row-click": _vm.rowClick,
                    "row-dblclick": _vm.rowDbClick,
                    "selection-change": _vm.selectionChange
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "60",
                      align: "center",
                      selectable: _vm.selectable,
                      fixed: ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      index: function(index) {
                        return _vm.indexMethod(index, _vm.stockForm)
                      },
                      label: "序号",
                      width: "80",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "barCode",
                      label: "产品主码",
                      width: "150",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "productNumber",
                      label: "产品编号",
                      width: "150",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "batchNumber",
                      label: "产品批号",
                      width: "150",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "150",
                      property: "",
                      label: "产地/品牌",
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(scope.row.productPlace) +
                                "/" +
                                _vm._s(scope.row.brandName)
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "productName",
                      label: "物资名称",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      property: "normalQuantity",
                      label: "库存可用数量",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      property: "specificationModel",
                      label: "规格型号",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      property: "unit",
                      label: "单位",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "200",
                      property: "registrationNumber",
                      label: "注册证号",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      property: "genericName",
                      label: "通用名",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      property: "manufacturerDate",
                      label: "生产日期",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      property: "validityDate",
                      label: "有效期",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      property: "afterTaxPrice",
                      label: "单价",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "100",
                      property: "returnQuantity",
                      label: "退货数量",
                      align: "center",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input-number", {
                              staticStyle: { width: "100%" },
                              attrs: { min: 0, controls: false, size: "mini" },
                              model: {
                                value: scope.row.returnQuantity,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "returnQuantity", $$v)
                                },
                                expression: "scope.row.returnQuantity"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { margin: "10px 0" } },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      align: "center",
                      "page-sizes": [5, 10, 20, 50, 100],
                      total: _vm.stockForm.total,
                      "current-page": _vm.stockForm.page,
                      "page-size": _vm.stockForm.pageSize
                    },
                    on: {
                      "current-change": _vm.stockListPageChange,
                      "size-change": _vm.stockListSizeChange
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { align: "center" } },
            [
              _c("el-button", { on: { click: _vm.drawerClose } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.importConfirm }
                },
                [_vm._v("添加")]
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }