import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { sendCode } from '@/api/global.js';
export default {
  components: {},
  data: function data() {
    var checkPhone = function checkPhone(rule, value, callback) {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        var reg = /^1[1|0|1|2|3|4|5|6|7|8|9][0-9]\d{8}$/;

        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };

    return {
      dialogTitle: '',
      dialogVisible: false,
      loading: false,
      sendCodeCD: 0,
      sendCodeLoading: false,
      form: {
        mobile: '',
        code: '',
        newPassword: ''
      },
      formRules: {
        newPassword: [{
          required: true,
          message: '请输入新密码',
          trigger: 'blur'
        }, {
          min: 6,
          message: '新密码至少6位'
        }],
        code: [{
          required: true,
          message: '请输入短信码',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号码(账号)',
          trigger: 'blur'
        }, {
          validator: checkPhone,
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(row) {
      var that = this;
      that.dialogVisible = true;
      that.dialogTitle = '忘记密码';
      that.form = {
        mobile: '',
        code: '',
        newPassword: ''
      };
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    formSubmit: function formSubmit() {
      var that = this; // var form = JSON.parse(JSON.stringify(that.form))

      this.$refs.form.validate(function (valid) {
        if (valid) {
          that.loading = true;
        }
      });
    },
    sendSmsCode: function sendSmsCode() {
      var that = this;

      if (that.sendCodeCD > 0) {
        return;
      }

      var reg = /^1[1|0|1|2|3|4|5|6|7|8|9][0-9]\d{8}$/;

      if (reg.test(that.form.mobile)) {
        that.sendCodeLoading = true;
        var form = {
          mobile: that.form.mobile,
          event: ''
        };
        sendCode(form).then(function (res) {
          that.sendCodeCD = 60;
          that.sendSmsCodeCD();
          that.$message.success('发送成功，请注意查收短信码');
        }).finally(function () {
          that.sendCodeLoading = false;
        });
      } else {
        that.$message.error('请输入正确的手机号');
      }
    },
    sendSmsCodeCD: function sendSmsCodeCD() {
      var that = this;

      if (that.sendCodeCD > 0) {
        that.sendCodeCD = that.sendCodeCD - 1;
        setTimeout(function () {
          that.sendSmsCodeCD();
        }, 1000);
      } else {
        that.sendCodeCD = 0;
      }
    }
  }
};