import { request } from '@/api/_service.js'; // 押金列表

export function listDistributor(params) {
  return request({
    url: '/erpFaOrgDistributorNew/getFaOrgDistributorList',
    method: 'get',
    params: params
  });
} // 增加押金、退款

export function createDeposit(data) {
  return request({
    url: '/manufacturerCashPledgeRecordErpNew/createManufacturerCashPledgeRecord',
    method: 'post',
    data: data
  });
} // 押金明细

export function listDetail(params) {
  return request({
    url: '/manufacturerCashPledgeRecordErpNew/getManufacturerCashPledgeRecordList',
    method: 'get',
    params: params
  });
}