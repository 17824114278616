import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { downloadSellTemplate } from '@/api/order.js';
import util from '@/libs/util.js';
import axios from 'axios';
import format from '@/libs/util.format';
import dayjs from 'dayjs';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: 'Excel产品明细导入',
      dialogVisible: false,
      uploadFileType: ['.xls', '.xlsx'],
      uploadURL: '',
      uploadToken: '',
      loading: false,
      errorMsg: null,
      successMsg: null,
      warehouseId: null
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(_ref) {
      var distributorId = _ref.distributorId;
      var that = this;
      that.dialogVisible = true;
      that.errorMsg = null;
      that.successMsg = null;
      that.uploadURL = process.env.VUE_APP_API + '/sellOrderDetailErpNew/uploadExcel?manufacturerId4=' + distributorId;
      that.uploadToken = {
        Authorization: util.cookies.get('token'),
        Os: 'web'
      };
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    myUpload: function myUpload(content) {
      var _this = this;

      var that = this;
      that.errorMsg = null;
      that.successMsg = null;
      var form = new FormData();
      form.append('file', content.file); // form.append('warehouseId', that.warehouseId)

      var config = {
        headers: content.headers,
        timeout: 1000 * 180
      };

      if (content.file.size / 1024 / 1024 > 30) {
        that.errorMsg = '不允许上传大于30MB的文件';
        that.$message.error(that.errorMsg);
        return false;
      }

      var FileExt = content.file.name.replace(/.+\./, '');

      if (that.uploadFileType.indexOf('.' + FileExt.toLowerCase()) === -1) {
        that.errorMsg = '不允许上传此类型文件';
        that.$message.error(that.errorMsg);
        return false;
      }

      this.loading = true;
      axios.post(content.action, form, config).then(function (res) {
        if (res.data.code !== 0) {
          that.errorMsg = res.data.msg;
          that.$message.error(that.errorMsg);
        } else {
          // that.$notify.success(res.data.msg)
          that.successMsg = res.data.msg;
          var listData = that.detailListFormat(res.data.data.list);
          that.$emit('add', listData);
        }
      }).catch(function (error) {
        if (error.response) {
          that.errorMsg = '文件上传失败,' + error.response.data;
        } else if (error.request) {
          that.errorMsg = '文件上传失败，服务器端无响应';
        } else {
          that.errorMsg = '文件上传失败，请求封装失败';
        }

        that.$message.error(that.errorMsg);
      }).finally(function () {
        _this.loading = false;
      });
    },
    detailListFormat: function detailListFormat(rows) {
      var resultRows = [];
      rows.forEach(function (row, index) {
        resultRows.push({
          id: null,
          rowIndex: index,
          productNumber: row.productNumber,
          specificationModel: row.specificationModel,
          medCode: row.medCode,
          unit: row.unit,
          packNum: row.packNum,
          packUnit: row.packUnit,
          unitPrice: row.wholesalePrice,
          wholesalePurchasePrice: row.wholesalePurchasePrice,
          consignmentPurchasePrice: row.consignmentPurchasePrice,
          marketPrice: row.marketPrice,
          consignmentPrice: row.consignmentPrice,
          wholesalePrice: row.wholesalePrice,
          mainId: row.mainId,
          specsId: row.ID,
          manufacturerName: row.manufacturerName,
          sellQuantity: row.sellQuantity,
          sellUnitPrice: row.sellUnitPrice,
          sellAmount: Number(Number(row.sellQuantity * row.sellUnitPrice).toFixed(2)),
          inventoryId: row.stockMain.ID,
          stockQuantity: row.stockMain.stock_quantity,
          normalQuantity: row.stockMain.normalQuantity,
          consumedQuantity: row.stockMain.consumedQuantity,
          receiptQuantity: row.stockMain.receiptQuantity,
          issueLocking: row.stockMain.issueLocking,
          salesPendingApproval: row.stockMain.salesPendingApproval,
          productPackageLocking: row.stockMain.productPackageLocking,
          batchNumber: row.stockMain.batch_number,
          manufacturerDate: row.stockMain.manufacturer_date,
          validityDate: row.stockMain.validity_date,
          sterilizationBatchNumber: row.stockMain.sterilization_batch_number,
          sterilizationDate: row.stockMain.sterilization_date,
          trackingCode: row.stockMain.trackingCode,
          productName: row.main.productName,
          registrationNumber: row.main.registrationNumber,
          productPlace: row.main.productPlace,
          brandName: row.main.brandName,
          expirationDate: row.main.expirationDate,
          manufacturer: row.main.manufacturer
        });
      });
      return resultRows;
    },
    templateDownload: function templateDownload() {
      downloadSellTemplate().then(function (res) {
        var blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        }); // type这里表示xlsx类型

        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接

        downloadElement.href = href;
        downloadElement.download = '采购订货单模板.xlsx'; // 下载后文件名

        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载

        document.body.removeChild(downloadElement); // 下载完成移除元素

        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    }
  }
};