import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import Vue from 'vue';
Vue.directive('Int', {
  inserted: function inserted(input) {
    input.addEventListener('keypress', function (e) {
      console.log(e);
      var charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode;
      var re = /\d/;
      console.log('input', input.children[0].value);

      if (input.children[0].value.length === 0 && charcode === 48) {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
      } else {
        if (!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey) {
          if (e.preventDefault) {
            e.preventDefault();
          } else {
            e.returnValue = false;
          }
        }
      }
    });

    input.onblur = function (e) {
      if (input.children[0].value.length === 1) {
        input.children[0].value = input.children[0].value.replace(/[^1-9]/g, '');
      } else {
        input.children[0].value = input.children[0].value.replace(/[^\d]/g, '');
      }

      trigger(input, 'input');
    };
  }
});

var trigger = function trigger(el, type) {
  var e = document.createEvent('HTMLEvents');
  e.initEvent(type, true, true);
  el.dispatchEvent(e);
};