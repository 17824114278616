import _objectSpread from "D:/wwwroot/\u6709\u53F0\u79D1\u6280\u4F9B\u5E94\u94FE\u534F\u540C\u5E73\u53F0/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import layoutHeaderAside from '@/layout/header-aside';

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/base',
  name: 'base',
  meta: meta,
  component: layoutHeaderAside,
  children: [{
    path: 'address',
    name: 'base-address-index',
    component: _import('base/address/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '地址管理'
    }),
    props: true
  }, {
    path: 'custom',
    name: 'base-custom-index',
    component: _import('base/custom/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '客户管理'
    }),
    props: true
  }, {
    path: 'corp',
    name: 'base-corp-index',
    component: _import('base/corp/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '我的公司'
    }),
    props: true
  }, {
    path: 'qualifications',
    name: 'base-qualifications-index',
    component: _import('base/qualifications/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '平台资质管理'
    }),
    props: true
  }]
};