var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("工作台首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("订货管理")]),
              _c("el-breadcrumb-item", [_vm._v("报台中心")]),
              _c("el-breadcrumb-item", [
                _vm._v(
                  _vm._s(Number(_vm.form.id) ? "报台单详情" : "新增报台单")
                )
              ])
            ],
            1
          ),
          _vm.orderRow.deliveryOrderNo
            ? _c("div", { staticStyle: { "font-size": "14px" } }, [
                _vm._v(
                  " 报台单号：" + _vm._s(_vm.orderRow.deliveryOrderNo) + " "
                )
              ])
            : _vm._e()
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "140px",
              "label-suffix": ":",
              "show-message": false,
              loading: _vm.formLoading,
              disabled: _vm.form.status !== 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "报台类型", prop: "multi" } },
                      [
                        _vm.form.status === 1
                          ? _c(
                              "el-radio-group",
                              {
                                on: { change: _vm.multiChange },
                                model: {
                                  value: _vm.form.multi,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "multi", $$v)
                                  },
                                  expression: "form.multi"
                                }
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("单台手术报台")
                                ]),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("多台手术报台")
                                ])
                              ],
                              1
                            )
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.form.multi === 1
                                      ? "单台手术报台"
                                      : "多台手术报台"
                                  ) +
                                  " "
                              )
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "经销商", prop: "distributorId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.detailList.length > 0
                            },
                            on: { change: _vm.distributorIdChange },
                            model: {
                              value: _vm.form.distributorId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "distributorId", $$v)
                              },
                              expression: "form.distributorId"
                            }
                          },
                          _vm._l(_vm.manufacturerList, function(item, index) {
                            return _c("el-option", {
                              key: "distributorId" + index,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled:
                                  item.show_status === false ? true : false
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "供应商名称" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderRow.organizationName))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户名称", prop: "clientId" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { flex: "1" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择"
                                    },
                                    model: {
                                      value: _vm.form.clientId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "clientId", $$v)
                                      },
                                      expression: "form.clientId"
                                    }
                                  },
                                  _vm._l(_vm.customList, function(item, index) {
                                    return _c("el-option", {
                                      key: "clientId" + index,
                                      attrs: {
                                        label: item.username,
                                        value: item.ID
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100px",
                                  "text-align": "center",
                                  "border-left": "1px solid #eee"
                                }
                              },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      underline: false,
                                      type: "primary",
                                      disabled: _vm.form.status !== 1
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.newCustom()
                                      }
                                    }
                                  },
                                  [_vm._v("新增客户")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "报台日期" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderRow.tradeDate))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "其他附件" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { flex: "1", "margin-left": "15px" }
                            },
                            [
                              _vm._v(
                                " 已上传附件 " +
                                  _vm._s(_vm.form.attachment.length) +
                                  " 个 "
                              ),
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    width: "500",
                                    trigger: "click"
                                  }
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        size: "small",
                                        data: _vm.form.attachment
                                      }
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "名称",
                                          prop: "name",
                                          width: "150"
                                        }
                                      }),
                                      _c("el-table-column", {
                                        attrs: { label: "附件", prop: "url" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _c(
                                                  "el-link",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      underline: false
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.attachPreview(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(row.url))]
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "操作",
                                          width: "60",
                                          align: "right"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _c(
                                                  "el-link",
                                                  {
                                                    attrs: {
                                                      type: "danger",
                                                      underline: false
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.attachRemove(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("删除")]
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "primary",
                                        underline: false
                                      },
                                      slot: "reference"
                                    },
                                    [_vm._v("查看")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100px",
                                "text-align": "center",
                                "border-left": "1px solid #eee"
                              }
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    action: _vm.uploadAction,
                                    headers: _vm.uploadToken,
                                    "on-success": _vm.attachSuccess,
                                    "show-file-list": false
                                  }
                                },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                        disabled: _vm.form.status !== 1
                                      }
                                    },
                                    [_vm._v("上传附件")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _vm.form.multi === 1
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "病人姓名", prop: "patientName" } },
                          [
                            _vm.form.status === 1
                              ? _c("el-input", {
                                  attrs: {
                                    maxlength: "50",
                                    clearable: "",
                                    "suffix-icon": "el-icon-edit"
                                  },
                                  model: {
                                    value: _vm.form.patientName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "patientName", $$v)
                                    },
                                    expression: "form.patientName"
                                  }
                                })
                              : _c("div", { staticClass: "item-text" }, [
                                  _vm._v(_vm._s(_vm.form.patientName))
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.multi === 1
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "病人年龄", prop: "patientAge" } },
                          [
                            _vm.form.status === 1
                              ? _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    "suffix-icon": "el-icon-edit"
                                  },
                                  model: {
                                    value: _vm.form.patientAge,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "patientAge",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "form.patientAge"
                                  }
                                })
                              : _c("div", { staticClass: "item-text" }, [
                                  _vm._v(_vm._s(_vm.form.patientAge))
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.multi === 1
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "病人性别", prop: "patientGender" }
                          },
                          [
                            _vm.form.status === 1
                              ? _c(
                                  "el-select",
                                  {
                                    model: {
                                      value: _vm.form.patientGender,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "patientGender", $$v)
                                      },
                                      expression: "form.patientGender"
                                    }
                                  },
                                  _vm._l(_vm.genderList, function(item, index) {
                                    return _c("el-option", {
                                      key: "formPatientGender" + index,
                                      attrs: {
                                        label: item.name,
                                        value: item.name
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _c("div", { staticClass: "item-text" }, [
                                  _vm._v(_vm._s(_vm.form.patientGender))
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.multi === 1
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "住院号", prop: "admissionNumber" }
                          },
                          [
                            _vm.form.status === 1
                              ? _c("el-input", {
                                  attrs: {
                                    maxlength: "50",
                                    clearable: "",
                                    "suffix-icon": "el-icon-edit"
                                  },
                                  model: {
                                    value: _vm.form.admissionNumber,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "admissionNumber", $$v)
                                    },
                                    expression: "form.admissionNumber"
                                  }
                                })
                              : _c("div", { staticClass: "item-text" }, [
                                  _vm._v(_vm._s(_vm.form.admissionNumber))
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.multi === 1
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "医生", prop: "" } },
                          [
                            _vm.form.status === 1
                              ? _c("el-input", {
                                  attrs: {
                                    maxlength: "50",
                                    clearable: "",
                                    "suffix-icon": "el-icon-edit"
                                  },
                                  model: {
                                    value: _vm.form.surgeon,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "surgeon", $$v)
                                    },
                                    expression: "form.surgeon"
                                  }
                                })
                              : _c("div", { staticClass: "item-text" }, [
                                  _vm._v(_vm._s(_vm.form.surgeon))
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.multi === 1
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "手术日期", prop: "operationTime" }
                          },
                          [
                            _vm.form.status === 1
                              ? _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    type: "date",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                    placeholder: "选择日期"
                                  },
                                  model: {
                                    value: _vm.form.operationTime,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "operationTime", $$v)
                                    },
                                    expression: "form.operationTime"
                                  }
                                })
                              : _c("div", { staticClass: "item-text" }, [
                                  _vm._v(_vm._s(_vm.form.operationTime))
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.multi === 1
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "手术名称", prop: "surgicalName" }
                          },
                          [
                            _vm.form.status === 1
                              ? _c("el-input", {
                                  attrs: {
                                    maxlength: "200",
                                    clearable: "",
                                    "suffix-icon": "el-icon-edit"
                                  },
                                  model: {
                                    value: _vm.form.surgicalName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "surgicalName", $$v)
                                    },
                                    expression: "form.surgicalName"
                                  }
                                })
                              : _c("div", { staticClass: "item-text" }, [
                                  _vm._v(_vm._s(_vm.form.surgicalName))
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  {
                    attrs: {
                      sm: _vm.form.multi === 1 ? 12 : 24,
                      lg: _vm.form.multi === 1 ? 16 : 24,
                      xl: _vm.form.multi === 1 ? 16 : 24
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "remarks", label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "255",
                            "suffix-icon": "el-icon-edit",
                            placeholder: "请输入备注",
                            clearable: ""
                          },
                          model: {
                            value: _vm.form.remarks,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "remarks", $$v)
                            },
                            expression: "form.remarks"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0",
              "align-items": "center"
            }
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-plus",
                      disabled: _vm.form.status !== 1
                    },
                    on: {
                      click: function($event) {
                        return _vm.addProduct()
                      }
                    }
                  },
                  [_vm._v("添加产品")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      size: "mini",
                      disabled: _vm.form.status !== 1
                    },
                    on: {
                      click: function($event) {
                        return _vm.scanBoxDisplaySwitch()
                      }
                    }
                  },
                  [_vm._v("扫码添加")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-upload2",
                      disabled: _vm.form.status !== 1
                    },
                    on: { click: _vm.excelImport }
                  },
                  [_vm._v("EXCEL导入")]
                )
              ],
              1
            ),
            _c("div", { staticStyle: { "font-size": "14px" } }, [
              _c("span", { staticStyle: { "margin-right": "20px" } }, [
                _vm._v("数量合计："),
                _c("span", { staticStyle: { color: "#f00" } }, [
                  _vm._v(_vm._s(_vm.quantitySum()))
                ])
              ]),
              _c("span", [
                _vm._v("金额合计："),
                _c("span", { staticStyle: { color: "#f00" } }, [
                  _vm._v(_vm._s(_vm.amountSum()))
                ])
              ])
            ])
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.scanBoxDisplay,
                expression: "scanBoxDisplay"
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  margin: "10px 0",
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "450px" } },
                  [
                    _c("el-input", {
                      ref: "scanInputBoxRef",
                      staticClass: "bar-input",
                      attrs: { placeholder: "请扫描产品条码", clearable: "" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.barCodeSearch.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.scanBarCode,
                        callback: function($$v) {
                          _vm.scanBarCode = $$v
                        },
                        expression: "scanBarCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#f00",
                      flex: "1",
                      "margin-left": "20px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.tipMessage))]
                )
              ]
            )
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading"
            }
          ],
          ref: "dataTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.detailForm,
            columns: _vm.detailColumns,
            data: _vm.detailList.slice(
              (_vm.detailForm.page - 1) * _vm.detailForm.pageSize,
              _vm.detailForm.page * _vm.detailForm.pageSize
            ),
            cacheName: _vm.detailTableCacheName,
            showDragButton: false
          },
          on: { "row-click": _vm.detailRowClick },
          scopedSlots: _vm._u([
            {
              key: "deliveryQuantity",
              fn: function(ref) {
                var row = ref.row
                return [
                  _vm.form.status === 1
                    ? _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          precision: 0,
                          controls: false,
                          size: "mini",
                          min: 0
                        },
                        on: {
                          change: function($event) {
                            return _vm.productSaveOne(row)
                          }
                        },
                        model: {
                          value: row.deliveryQuantity,
                          callback: function($$v) {
                            _vm.$set(row, "deliveryQuantity", $$v)
                          },
                          expression: "row.deliveryQuantity"
                        }
                      })
                    : _c("span", [_vm._v(_vm._s(row.deliveryQuantity))])
                ]
              }
            },
            {
              key: "hospitalUnitPrice",
              fn: function(ref) {
                var row = ref.row
                return [
                  _vm.form.status === 1
                    ? _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          precision: 2,
                          controls: false,
                          size: "mini",
                          min: 0
                        },
                        on: {
                          change: function($event) {
                            return _vm.productSaveOne(row)
                          }
                        },
                        model: {
                          value: row.hospitalUnitPrice,
                          callback: function($$v) {
                            _vm.$set(row, "hospitalUnitPrice", $$v)
                          },
                          expression: "row.hospitalUnitPrice"
                        }
                      })
                    : _c("span", [_vm._v(_vm._s(row.hospitalUnitPrice))])
                ]
              }
            },
            {
              key: "operationTime",
              fn: function(ref) {
                var row = ref.row
                return [
                  _vm.form.status === 1
                    ? _c("el-date-picker", {
                        staticStyle: { width: "130px" },
                        attrs: {
                          size: "mini",
                          type: "date",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "选择日期"
                        },
                        on: {
                          change: function($event) {
                            return _vm.productSaveOne(row)
                          }
                        },
                        model: {
                          value: row.operationTime,
                          callback: function($$v) {
                            _vm.$set(row, "operationTime", $$v)
                          },
                          expression: "row.operationTime"
                        }
                      })
                    : _c("span", [_vm._v(_vm._s(row.operationTime))])
                ]
              }
            },
            {
              key: "surgeon",
              fn: function(ref) {
                var row = ref.row
                return [
                  _vm.form.status === 1
                    ? _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { size: "mini", clearable: "" },
                        on: {
                          change: function($event) {
                            return _vm.productSaveOne(row)
                          }
                        },
                        model: {
                          value: row.surgeon,
                          callback: function($$v) {
                            _vm.$set(row, "surgeon", $$v)
                          },
                          expression: "row.surgeon"
                        }
                      })
                    : _c("span", [_vm._v(_vm._s(row.surgeon))])
                ]
              }
            },
            {
              key: "patientName",
              fn: function(ref) {
                var row = ref.row
                return [
                  _vm.form.status === 1
                    ? _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { size: "mini", clearable: "" },
                        on: {
                          change: function($event) {
                            return _vm.productSaveOne(row)
                          }
                        },
                        model: {
                          value: row.patientName,
                          callback: function($$v) {
                            _vm.$set(row, "patientName", $$v)
                          },
                          expression: "row.patientName"
                        }
                      })
                    : _c("span", [_vm._v(_vm._s(row.patientName))])
                ]
              }
            },
            {
              key: "patientGender",
              fn: function(ref) {
                var row = ref.row
                return [
                  _vm.form.status === 1
                    ? _c(
                        "el-select",
                        {
                          attrs: { size: "mini", clearable: "" },
                          on: {
                            change: function($event) {
                              return _vm.productSaveOne(row)
                            }
                          },
                          model: {
                            value: row.patientGender,
                            callback: function($$v) {
                              _vm.$set(row, "patientGender", $$v)
                            },
                            expression: "row.patientGender"
                          }
                        },
                        _vm._l(_vm.genderList, function(item, index) {
                          return _c("el-option", {
                            key: "patientGender" + index,
                            attrs: { label: item.name, value: item.name }
                          })
                        }),
                        1
                      )
                    : _c("span", [
                        _vm._v(_vm._s(_vm.genderFormatter(row.patientGender)))
                      ])
                ]
              }
            },
            {
              key: "patientAge",
              fn: function(ref) {
                var row = ref.row
                return [
                  _vm.form.status === 1
                    ? _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { size: "mini" },
                        on: {
                          change: function($event) {
                            return _vm.productSaveOne(row)
                          }
                        },
                        model: {
                          value: row.patientAge,
                          callback: function($$v) {
                            _vm.$set(row, "patientAge", _vm._n($$v))
                          },
                          expression: "row.patientAge"
                        }
                      })
                    : _c("span", [_vm._v(_vm._s(row.patientAge))])
                ]
              }
            },
            {
              key: "admissionNumber",
              fn: function(ref) {
                var row = ref.row
                return [
                  _vm.form.status === 1
                    ? _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { size: "mini", clearable: "" },
                        on: {
                          change: function($event) {
                            return _vm.productSaveOne(row)
                          }
                        },
                        model: {
                          value: row.admissionNumber,
                          callback: function($$v) {
                            _vm.$set(row, "admissionNumber", $$v)
                          },
                          expression: "row.admissionNumber"
                        }
                      })
                    : _c("span", [_vm._v(_vm._s(row.admissionNumber))])
                ]
              }
            }
          ])
        }),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
                align: "center",
                total: _vm.detailForm.total,
                "page-sizes": [5, 10, 15, 20, 30, 40, 50, 100],
                "current-page": _vm.detailForm.page,
                "page-size": _vm.detailForm.pageSize
              },
              on: {
                "current-change": _vm.detailPageChange,
                "size-change": _vm.detailSizeChange
              }
            })
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" } },
                  [
                    _vm.form.status === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              disabled: _vm.detailRowIndex >= 0 ? false : true
                            },
                            on: {
                              click: function($event) {
                                return _vm.productRemove()
                              }
                            }
                          },
                          [_vm._v("删除行")]
                        )
                      : _vm._e(),
                    _vm.form.status === 1 && _vm.form.id
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.orderDelete()
                              }
                            }
                          },
                          [_vm._v("删除订单")]
                        )
                      : _vm._e(),
                    _vm.form.status === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.formStorage(true)
                              }
                            }
                          },
                          [_vm._v("暂存")]
                        )
                      : _vm._e(),
                    _vm.form.status === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.formSubmit()
                              }
                            }
                          },
                          [_vm._v("提交")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.orderPrint }
                      },
                      [_vm._v("打印")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("CustomComponent", {
        ref: "CustomComponentRef",
        on: {
          reload: function($event) {
            return _vm.loadCustom()
          }
        }
      }),
      _c("ProductComponent", {
        ref: "ProductComponentRef",
        on: {
          reload: function($event) {
            return _vm.loadDetail()
          },
          add: _vm.addProductToDetailList
        }
      }),
      _c("PrintComponent", { ref: "PrintComponentRef" }),
      _c("InvoiceComponent", { ref: "InvoiceComponentRef" }),
      _c("ExcelImportComponent", {
        ref: "ExcelImportComponentRef",
        on: { add: _vm.addProductToDetailList }
      }),
      _c("OrderImportComponent", {
        ref: "OrderImportComponentRef",
        on: { add: _vm.addProductToDetailList }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }