import { request } from '@/api/_service.js'; // 获取我拥有的组织

export function listDealerUserOrganization(params) {
  return request({
    url: '/erp/get_dealer_user_organization_list',
    method: 'get',
    params: params
  });
} // 绑定组织

export function bindOrganization(data) {
  return request({
    url: '/erp/bind_organization_id',
    method: 'post',
    data: data
  });
}
export function applyPost(data) {
  return request({
    url: '/dealerClientUser/createDealerClientUser',
    method: 'post',
    data: data
  });
}