import _objectSpread from "D:/wwwroot/\u6709\u53F0\u79D1\u6280\u4F9B\u5E94\u94FE\u534F\u540C\u5E73\u53F0/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import layoutHeaderAside from '@/layout/header-aside';

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/report',
  name: 'report',
  meta: meta,
  component: layoutHeaderAside,
  children: [{
    path: 'center',
    name: 'report-center-index',
    component: _import('report/center/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '报台中心'
    }),
    props: true
  }, {
    path: 'center/create',
    name: 'report-center-create',
    component: _import('report/center/create.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '新建报台单'
    }),
    props: true
  }, {
    path: 'center/detail/:id',
    name: 'report-center-detail',
    component: _import('report/center/detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '报台单详情'
    }),
    props: true
  }]
};