var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            { attrs: { inline: true, size: "mini", "label-suffix": ":" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "报台单号" } },
                [_c("el-input")],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-button", { attrs: { type: "primary" } }, [
                    _vm._v("查询")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: {
                size: "small",
                border: "",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                }
              }
            },
            [
              _c("el-table-column", { attrs: { label: "报台单号" } }),
              _c("el-table-column", { attrs: { label: "报台单号" } }),
              _c("el-table-column", { attrs: { label: "报台单号" } }),
              _c("el-table-column", { attrs: { label: "报台单号" } }),
              _c("el-table-column", { attrs: { label: "报台单号" } })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "10px 0" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                  align: "center",
                  total: _vm.form.total,
                  "page-sizes": [5, 10, 15, 20, 30, 40, 50, 100],
                  "current-page": _vm.form.page,
                  "page-size": _vm.form.pageSize
                },
                on: {
                  "current-change": _vm.pageChange,
                  "size-change": _vm.sizeChange
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }