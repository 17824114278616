var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "500px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm.appRow.manuId
        ? _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "130px",
                "label-suffix": ":",
                model: _vm.form,
                rules: _vm.formRules,
                "show-message": false,
                size: "mini"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                },
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.searchFormSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "经销商名称", prop: "manuId" } },
                [_c("span", [_vm._v(_vm._s(_vm.appRow.manuName))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "货款押金退款金额", prop: "productPrice" } },
                [
                  _vm.action === "EDIT"
                    ? _c(
                        "div",
                        { staticStyle: { "font-size": "12px" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { "margin-right": "10px" },
                            attrs: {
                              min: 0,
                              max: _vm.appRow.dealerProductPrice,
                              precision: 2,
                              controls: false
                            },
                            model: {
                              value: _vm.form.productPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "productPrice", $$v)
                              },
                              expression: "form.productPrice"
                            }
                          }),
                          _vm._v(" 货款押金总额: "),
                          _c(
                            "el-link",
                            {
                              staticStyle: { "font-size": "12px" },
                              attrs: { type: "primary", underline: false }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.appRow.dealerProductPrice.toFixed(2))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticStyle: { "font-size": "12px", display: "flex" }
                        },
                        [
                          _c("div", { staticStyle: { width: "100px" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.moneyFormatter(_vm.appRow.productPrice)
                              )
                            )
                          ]),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "20px" } },
                            [
                              _vm._v("货款押金总额: "),
                              _c(
                                "el-link",
                                {
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { type: "primary", underline: false }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.appRow.dealerProductPrice.toFixed(2)
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "工具押金退款金额", prop: "toolPrice" } },
                [
                  _vm.action === "EDIT"
                    ? _c(
                        "div",
                        { staticStyle: { "font-size": "12px" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { "margin-right": "10px" },
                            attrs: {
                              min: 0,
                              max: _vm.appRow.dealerToolPrice,
                              precision: 2,
                              controls: false
                            },
                            model: {
                              value: _vm.form.toolPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "toolPrice", $$v)
                              },
                              expression: "form.toolPrice"
                            }
                          }),
                          _vm._v(" 工具押金总额: "),
                          _c(
                            "el-link",
                            {
                              staticStyle: { "font-size": "12px" },
                              attrs: { type: "primary", underline: false }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.appRow.dealerToolPrice.toFixed(2))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticStyle: { "font-size": "12px", display: "flex" }
                        },
                        [
                          _c("div", { staticStyle: { width: "100px" } }, [
                            _vm._v(
                              _vm._s(_vm.moneyFormatter(_vm.appRow.toolPrice))
                            )
                          ]),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "20px" } },
                            [
                              _vm._v("工具押金总额: "),
                              _c(
                                "el-link",
                                {
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { type: "primary", underline: false }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.appRow.dealerToolPrice.toFixed(2)
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收款公司", prop: "collectionCompanyName" } },
                [
                  _vm.action === "EDIT"
                    ? _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.form.collectionCompanyName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "collectionCompanyName", $$v)
                          },
                          expression: "form.collectionCompanyName"
                        }
                      })
                    : _c("span", [
                        _vm._v(_vm._s(_vm.appRow.collectionCompanyName))
                      ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收款银行", prop: "collectionCompanyBank" } },
                [
                  _vm.action === "EDIT"
                    ? _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.form.collectionCompanyBank,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "collectionCompanyBank", $$v)
                          },
                          expression: "form.collectionCompanyBank"
                        }
                      })
                    : _c("span", [
                        _vm._v(_vm._s(_vm.appRow.collectionCompanyBank))
                      ])
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "收款账号",
                    prop: "collectionCompanyBankAccount"
                  }
                },
                [
                  _vm.action === "EDIT"
                    ? _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.form.collectionCompanyBankAccount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "collectionCompanyBankAccount",
                              $$v
                            )
                          },
                          expression: "form.collectionCompanyBankAccount"
                        }
                      })
                    : _c("span", [
                        _vm._v(_vm._s(_vm.appRow.collectionCompanyBankAccount))
                      ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "mark" } },
                [
                  _vm.action === "EDIT"
                    ? _c("el-input", {
                        attrs: { clearable: "", filterable: "" },
                        model: {
                          value: _vm.form.mark,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "mark", $$v)
                          },
                          expression: "form.mark"
                        }
                      })
                    : _c("span", [_vm._v(_vm._s(_vm.appRow.mark))])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { attrs: { slot: "footer", align: "center" }, slot: "footer" },
        [
          _vm.action === "EDIT"
            ? _c(
                "div",
                [
                  _c("el-button", { on: { click: _vm.dialogClose } }, [
                    _vm._v("取 消")
                  ]),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.formSubmit }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("el-button", { on: { click: _vm.dialogClose } }, [
                    _vm._v("关 闭")
                  ]),
                  _vm.form.status === 3
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.reEdit }
                        },
                        [_vm._v("重新编辑")]
                      )
                    : _vm._e()
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }