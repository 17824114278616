var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("工作台首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("订货管理")]),
              _c("el-breadcrumb-item", [_vm._v("寄售订货订单")]),
              _c("el-breadcrumb-item", [
                _vm._v(
                  _vm._s(_vm.form.id ? "寄售订货订单详情" : "新增寄售订货订单")
                )
              ])
            ],
            1
          ),
          _vm.orderRow.orderNumber
            ? _c("div", { staticStyle: { "font-size": "14px" } }, [
                _vm._v(" 订单编号：" + _vm._s(_vm.orderRow.orderNumber) + " ")
              ])
            : _vm._e()
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "140px",
              "label-suffix": ":",
              "show-message": false,
              loading: _vm.formLoading,
              disabled: !(
                _vm.form.state === 8 ||
                _vm.form.state === 9 ||
                _vm.form.state === 10
              )
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "经销商", prop: "distributorId" } },
                      [
                        _vm.form.state === 8 ||
                        _vm.form.state === 9 ||
                        _vm.form.state === 10
                          ? _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择"
                                },
                                on: { change: _vm.distributorIdChange },
                                model: {
                                  value: _vm.form.distributorId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "distributorId", $$v)
                                  },
                                  expression: "form.distributorId"
                                }
                              },
                              _vm._l(_vm.manufacturerList, function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: "distributorId" + index,
                                  attrs: {
                                    label: item.name,
                                    value: item.ID,
                                    disabled: !item.show_status
                                  }
                                })
                              }),
                              1
                            )
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderRow.distributorName))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "供应商名称", prop: "subsidiaryName" }
                      },
                      [
                        _vm.subsidiaryList.length > 0
                          ? _c(
                              "div",
                              [
                                _vm.form.state === 8 ||
                                _vm.form.state === 9 ||
                                _vm.form.state === 10
                                  ? _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          filterable: "",
                                          placeholder: "请选择"
                                        },
                                        model: {
                                          value: _vm.form.subsidiaryName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "subsidiaryName",
                                              $$v
                                            )
                                          },
                                          expression: "form.subsidiaryName"
                                        }
                                      },
                                      _vm._l(_vm.subsidiaryList, function(
                                        item,
                                        index
                                      ) {
                                        return _c("el-option", {
                                          key: "subsidiaryName" + index,
                                          attrs: {
                                            label: item.companyName,
                                            value: item.companyName,
                                            disabled: item.isValid !== 1
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  : _c("div", { staticClass: "item-text" }, [
                                      _vm._v(_vm._s(_vm.form.subsidiaryName))
                                    ])
                              ],
                              1
                            )
                          : _c("div", [
                              _c("div", { staticClass: "item-text" }, [
                                _vm._v(_vm._s(_vm.form.subsidiaryName))
                              ])
                            ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "收货地址", prop: "receiveAddress" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { flex: "1" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { clearable: "", filterable: "" },
                                    on: { change: _vm.addressChange },
                                    model: {
                                      value: _vm.form.receiveAddressId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "receiveAddressId",
                                          $$v
                                        )
                                      },
                                      expression: "form.receiveAddressId"
                                    }
                                  },
                                  _vm._l(_vm.addressList, function(
                                    item,
                                    index
                                  ) {
                                    return _c("el-option", {
                                      key: "receiveAddressId" + index,
                                      attrs: {
                                        label: item.address,
                                        value: item.ID
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100px",
                                  "text-align": "center",
                                  "border-left": "1px solid #eee"
                                }
                              },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      underline: false,
                                      type: "primary",
                                      disabled: !(
                                        _vm.form.state === 8 ||
                                        _vm.form.state === 9 ||
                                        _vm.form.state === 10
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.newAddress()
                                      }
                                    }
                                  },
                                  [_vm._v("新增地址")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "收件人", prop: "receiveName" } },
                      [
                        _c("div", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.form.receiveName))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "收件人联系方式", prop: "contactInfo" }
                      },
                      [
                        _c("div", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.form.contactInfo))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "remarks", label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "255",
                            "suffix-icon": "el-icon-edit",
                            placeholder: "请输入备注",
                            clearable: ""
                          },
                          model: {
                            value: _vm.form.remarks,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "remarks", $$v)
                            },
                            expression: "form.remarks"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0",
              "align-items": "center"
            }
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-plus",
                      disabled: !(
                        _vm.form.state === 8 ||
                        _vm.form.state === 9 ||
                        _vm.form.state === 10
                      )
                    },
                    on: {
                      click: function($event) {
                        return _vm.addProduct()
                      }
                    }
                  },
                  [_vm._v("添加产品")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      size: "mini",
                      disabled: !(
                        _vm.form.state === 8 ||
                        _vm.form.state === 9 ||
                        _vm.form.state === 10
                      )
                    },
                    on: {
                      click: function($event) {
                        return _vm.scanBoxDisplaySwitch()
                      }
                    }
                  },
                  [_vm._v("扫码添加")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-upload2",
                      disabled: !(
                        _vm.form.state === 8 ||
                        _vm.form.state === 9 ||
                        _vm.form.state === 10
                      )
                    },
                    on: { click: _vm.excelImport }
                  },
                  [_vm._v("EXCEL导入")]
                )
              ],
              1
            ),
            _c("div", { staticStyle: { "font-size": "14px" } }, [
              _c("span", { staticStyle: { "margin-right": "20px" } }, [
                _vm._v("数量合计："),
                _c("span", { staticStyle: { color: "#f00" } }, [
                  _vm._v(_vm._s(_vm.quantitySum()))
                ])
              ]),
              _vm.tabActiveId === 1
                ? _c("span", [
                    _vm._v("金额合计："),
                    _c("span", { staticStyle: { color: "#f00" } }, [
                      _vm._v(_vm._s(_vm.amountSum()))
                    ])
                  ])
                : _vm._e()
            ])
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.scanBoxDisplay,
                expression: "scanBoxDisplay"
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  margin: "10px 0",
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "450px" } },
                  [
                    _c("el-input", {
                      ref: "scanInputBoxRef",
                      staticClass: "bar-input",
                      attrs: { placeholder: "请扫描产品条码", clearable: "" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.barCodeSearch.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.scanBarCode,
                        callback: function($$v) {
                          _vm.scanBarCode = $$v
                        },
                        expression: "scanBarCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#f00",
                      flex: "1",
                      "margin-left": "20px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.tipMessage))]
                )
              ]
            )
          ]
        ),
        _vm.orderRow.sumDeliveryQuantityAll > 0
          ? _c("div", { staticClass: "table-header-tablist" }, [
              _c(
                "div",
                {
                  class: _vm.tabActiveId === 1 ? "item hover" : "item",
                  on: {
                    click: function($event) {
                      return _vm.tabChange(1)
                    }
                  }
                },
                [_vm._v("订单详情")]
              ),
              _c(
                "div",
                {
                  class: _vm.tabActiveId === 2 ? "item hover" : "item",
                  on: {
                    click: function($event) {
                      return _vm.tabChange(2)
                    }
                  }
                },
                [_vm._v("发货详情")]
              )
            ])
          : _vm._e(),
        _vm.tabActiveId === 1
          ? _c(
              "div",
              [
                _c("YTable", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.detailLoading,
                      expression: "detailLoading"
                    }
                  ],
                  key: "table" + _vm.timestamp,
                  ref: "dataTableRef",
                  attrs: {
                    hasIndex: true,
                    pagination: _vm.detailForm,
                    columns:
                      _vm.tabActiveId === 1
                        ? _vm.detailColumns
                        : _vm.deliveryColumns,
                    data: _vm.detailList,
                    cacheName: _vm.detailTableCacheName
                  },
                  on: { "row-click": _vm.detailRowClick },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "sellQuantity",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm.form.state === 8 ||
                            _vm.form.state === 9 ||
                            _vm.form.state === 10
                              ? _c("el-input-number", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    precision: 0,
                                    controls: false,
                                    size: "mini",
                                    min: 0
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.productSaveOne(row)
                                    }
                                  },
                                  model: {
                                    value: row.sellQuantity,
                                    callback: function($$v) {
                                      _vm.$set(row, "sellQuantity", $$v)
                                    },
                                    expression: "row.sellQuantity"
                                  }
                                })
                              : _c("span", [_vm._v(_vm._s(row.sellQuantity))])
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1193261656
                  )
                }),
                _c(
                  "div",
                  { staticStyle: { margin: "10px 0" } },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        layout: "total, prev, pager, next, jumper",
                        align: "center",
                        total: _vm.detailForm.total,
                        "current-page": _vm.detailForm.page,
                        "page-size": _vm.detailForm.pageSize
                      },
                      on: { "current-change": _vm.detailPageChange }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.tabActiveId === 2
          ? _c(
              "div",
              [
                _c(
                  "el-form",
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          { name: "tableFit", rawName: "v-tableFit" }
                        ],
                        ref: "deliveryListTableRef",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.stockoutList,
                          border: "",
                          "highlight-current-row": "",
                          size: "mini",
                          "header-cell-style": {
                            background: "#f5f7fa",
                            borderColor: "#ebeef5",
                            color: "#333"
                          }
                        },
                        on: { "selection-change": _vm.selectionChange }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            width: "50",
                            align: "center",
                            fixed: "left",
                            selectable: _vm.selectable
                          }
                        }),
                        _c("el-table-column", {
                          staticStyle: { padding: "0" },
                          attrs: {
                            type: "expand",
                            width: "50",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(props) {
                                  return [
                                    _c(
                                      "el-table",
                                      {
                                        ref: "table-" + props.row.ID,
                                        attrs: {
                                          data: props.row.detailList,
                                          size: "mini",
                                          "row-key": function(row) {
                                            return _vm.detailRowKey(
                                              row,
                                              "productList"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            type: "index",
                                            width: "50",
                                            label: "#",
                                            align: "center"
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "产品编号",
                                            prop: "specs.productNumber",
                                            width: "100",
                                            align: "center",
                                            "show-overflow-tooltip": ""
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "产品名称",
                                            prop: "main.productName",
                                            "min-width": "150",
                                            align: "center",
                                            "show-overflow-tooltip": ""
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "规格型号",
                                            prop: "specs.specificationModel",
                                            width: "100",
                                            align: "center",
                                            "show-overflow-tooltip": ""
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "单位",
                                            prop: "specs.unit",
                                            width: "100",
                                            align: "center",
                                            "show-overflow-tooltip": ""
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "批号",
                                            prop: "stockMain.batch_number",
                                            width: "100",
                                            align: "center",
                                            "show-overflow-tooltip": ""
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "序列号",
                                            prop: "stockMain.trackingCode",
                                            width: "100",
                                            align: "center",
                                            "show-overflow-tooltip": ""
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "数量",
                                            prop: "deliveryQuantity",
                                            width: "100",
                                            align: "center",
                                            "show-overflow-tooltip": ""
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "生产日期",
                                            prop: "stockMain.manufacturer_date",
                                            width: "100",
                                            align: "center",
                                            "show-overflow-tooltip": ""
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "有效期",
                                            prop: "stockMain.validity_date",
                                            width: "100",
                                            align: "center",
                                            "show-overflow-tooltip": ""
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "灭菌批号",
                                            prop:
                                              "stockMain.sterilization_batch_number",
                                            width: "100",
                                            align: "center",
                                            "show-overflow-tooltip": ""
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "灭菌效期",
                                            prop:
                                              "stockMain.sterilization_date",
                                            width: "100",
                                            align: "center",
                                            "show-overflow-tooltip": ""
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "注册证/备案证",
                                            prop:
                                              "stockMain.registrationNumber",
                                            width: "100",
                                            align: "center",
                                            "show-overflow-tooltip": ""
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "厂家",
                                            prop: "main.manufacturer",
                                            "min-width": "150",
                                            align: "center",
                                            "show-overflow-tooltip": ""
                                          }
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "生产许可证/备案凭证",
                                            prop: "main.manu.manu_license_code",
                                            "min-width": "150",
                                            align: "center",
                                            "show-overflow-tooltip": ""
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2545013479
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "出库单号",
                            prop: "deliveryOrderNo",
                            width: "200",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "状态",
                            prop: "status",
                            width: "100",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.stockoutStatusFormatter(
                                            row.status
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3270724432
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "出库人员",
                            prop: "nameWarehousePersonnel",
                            width: "100",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "出库时间",
                            prop: "deliveryTime",
                            width: "150",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "审核人",
                            prop: "checkName",
                            width: "100",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "审核时间",
                            prop: "checkDate",
                            width: "150",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "备注",
                            prop: "remarks",
                            "min-width": "150",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            prop: "operations",
                            width: "100",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    row.status === 4
                                      ? _c(
                                          "el-link",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.receiveConfirm([row])
                                              }
                                            }
                                          },
                                          [_vm._v("确认收货")]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2081499062
                          )
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { margin: "10px 0" } },
                      [
                        _c("el-pagination", {
                          attrs: {
                            background: "",
                            layout: "total, prev, pager, next, jumper",
                            align: "center",
                            total: _vm.stockForm.total,
                            "current-page": _vm.stockForm.page,
                            "page-size": _vm.stockForm.pageSize
                          },
                          on: { "current-change": _vm.stockPageChange }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" } },
                  [
                    _vm.form.state === 8 ||
                    _vm.form.state === 9 ||
                    _vm.form.state === 10
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              disabled: _vm.detailRowIndex >= 0 ? false : true
                            },
                            on: {
                              click: function($event) {
                                return _vm.productRemove()
                              }
                            }
                          },
                          [_vm._v("删除行")]
                        )
                      : _vm._e(),
                    (_vm.form.state === 8 ||
                      _vm.form.state === 9 ||
                      _vm.form.state === 10) &&
                    _vm.form.id
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.orderInvalid()
                              }
                            }
                          },
                          [_vm._v("作废")]
                        )
                      : _vm._e(),
                    (_vm.form.state === 8 ||
                      _vm.form.state === 9 ||
                      _vm.form.state === 10) &&
                    _vm.form.id
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.orderDelete()
                              }
                            }
                          },
                          [_vm._v("删除订单")]
                        )
                      : _vm._e(),
                    _vm.form.state === 8 ||
                    _vm.form.state === 9 ||
                    _vm.form.state === 10
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.formStorage()
                              }
                            }
                          },
                          [_vm._v("暂存")]
                        )
                      : _vm._e(),
                    _vm.form.state === 8 ||
                    _vm.form.state === 9 ||
                    _vm.form.state === 10
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.formSubmit()
                              }
                            }
                          },
                          [_vm._v("提交")]
                        )
                      : _vm._e(),
                    _vm.form.state === 11 && _vm.orderRow.source === 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.unFormSubmit()
                              }
                            }
                          },
                          [_vm._v("撤回")]
                        )
                      : _vm._e(),
                    _vm.orderRow.deliveryState === 3 ||
                    _vm.orderRow.deliveryState === 4
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: {
                              type: "primary",
                              disabled:
                                _vm.selectionRows.length > 0 ? false : true
                            },
                            on: {
                              click: function($event) {
                                return _vm.receiveConfirm(_vm.selectionRows)
                              }
                            }
                          },
                          [_vm._v("确认收货")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.orderPrint }
                      },
                      [_vm._v("打印")]
                    ),
                    _vm.orderRow.sumDeliveryQuantityAll > 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", disabled: !_vm.form.id },
                            on: { click: _vm.orderOutPrint }
                          },
                          [_vm._v("打印发货单")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("AddressComponent", {
        ref: "AddressComponentRef",
        on: {
          reload: function($event) {
            return _vm.loadAddress()
          }
        }
      }),
      _c("ProductComponent", {
        ref: "ProductComponentRef",
        on: {
          reload: function($event) {
            return _vm.loadDetail()
          },
          add: _vm.addProductToDetailList
        }
      }),
      _c("PrintComponent", { ref: "PrintComponentRef" }),
      _c("PrintOutComponent", { ref: "PrintOutComponentRef" }),
      _c("ImportComponent", {
        ref: "ImportComponentRef",
        on: { add: _vm.addProductToDetailList }
      }),
      _c("ConfirmComponent", {
        ref: "ConfirmComponentRef",
        on: {
          reload: function($event) {
            return _vm.loadStockout()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }