var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("工作台首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("基础数据")]),
              _c("el-breadcrumb-item", [_vm._v("我的公司")])
            ],
            1
          ),
          _c("div")
        ],
        1
      ),
      [
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dataLoading,
              expression: "dataLoading"
            }
          ],
          ref: "dataTableRef",
          attrs: {
            hasSelection: true,
            hasIndex: true,
            pagination: _vm.searchForm,
            columns: _vm.columns,
            data: _vm.dataList,
            cacheName: _vm.dataTableCacheName
          },
          on: { "row-dblclick": _vm.dataRowDbClick },
          scopedSlots: _vm._u([
            {
              key: "platform_code",
              fn: function(ref) {
                var row = ref.row
                return [
                  _vm._v(" " + _vm._s(row.platform_code) + " "),
                  _c(
                    "el-link",
                    {
                      attrs: { type: "primary", underline: false },
                      on: {
                        click: function($event) {
                          return _vm.copyToClipboard(row.platform_code)
                        }
                      }
                    },
                    [_vm._v("复制")]
                  )
                ]
              }
            },
            {
              key: "platform",
              fn: function(ref) {
                var row = ref.row
                return [
                  !row.platform.ID ? _c("span", [_vm._v("未绑定")]) : _vm._e(),
                  _c(
                    "el-link",
                    { attrs: { type: "primary", underline: false } },
                    [_vm._v(_vm._s(row.platform.organization.name))]
                  )
                ]
              }
            }
          ])
        }),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
                align: "center",
                total: _vm.searchForm.total,
                "page-sizes": [10, 15, 20, 30, 40, 50, 100],
                "current-page": _vm.searchForm.page,
                "page-size": _vm.searchForm.pageSize
              },
              on: {
                "current-change": _vm.pageChange,
                "size-change": _vm.sizeChange
              }
            })
          ],
          1
        )
      ],
      _c("template", { slot: "footer" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }