export default {
  path: '/base',
  title: '基础数据管理',
  icon: 'archive',
  children: [{
    path: '/base/address',
    title: '地址管理',
    icon: 'th-large'
  }, {
    path: '/base/custom',
    title: '客户管理',
    icon: 'th-large'
  }, {
    path: '/base/corp',
    title: '我的公司',
    icon: 'th-large'
  }, {
    path: '/base/qualifications',
    title: '平台资质管理',
    icon: 'th-large'
  }]
};