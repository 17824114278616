var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("基础数据管理")]),
              _c("el-breadcrumb-item", [_vm._v("平台资质管理")])
            ],
            1
          ),
          _c("div")
        ],
        1
      ),
      [
        _c("div", { staticStyle: { "line-height": "30px" } }, [
          _c(
            "div",
            { staticStyle: { "font-weight": "bold", color: "#409eff" } },
            [_vm._v("平台资质下载:")]
          ),
          _vm.licenseUploadFileList.length > 0
            ? _c(
                "div",
                { staticStyle: { "margin-left": "50px" } },
                [
                  _c("div", [_vm._v("平台营业执照下载:")]),
                  _vm._l(_vm.licenseUploadFileList, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: "licenseUploadFileList" + index,
                        staticStyle: { "margin-left": "20px" }
                      },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              href: _vm.uploadFilePrefixURL + item.url,
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm.businessUploadFileList.length > 0
            ? _c(
                "div",
                { staticStyle: { "margin-left": "50px" } },
                [
                  _c("div", [_vm._v("平台经营许可证下载:")]),
                  _vm._l(_vm.businessUploadFileList, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: "businessUploadFileList" + index,
                        staticStyle: { "margin-left": "20px" }
                      },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              href: _vm.uploadFilePrefixURL + item.url,
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm.manuUploadFileList.length > 0
            ? _c(
                "div",
                { staticStyle: { "margin-left": "50px" } },
                [
                  _c("div", [_vm._v("平台生产证可证下载:")]),
                  _vm._l(_vm.manuUploadFileList, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: "manuUploadFileList" + index,
                        staticStyle: { "margin-left": "20px" }
                      },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              href: _vm.uploadFilePrefixURL + item.url,
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ])
      ],
      _c("template", { slot: "footer" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }