import { request } from '@/api/_service.js';
export function listAgentReturn(params) {
  return request({
    url: '/erpConsignReturnNew/getErpConsignReturnList',
    method: 'get',
    params: params
  });
}
export function listAgentReturnExport(params) {
  return request({
    url: '/erpConsignReturnNew/getErpConsignReturnList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findAgentReturn(params) {
  return request({
    url: '/erpConsignReturnNew/findErpConsignReturn',
    method: 'get',
    params: params
  });
}
export function createAgentReturn(data) {
  return request({
    url: '/erpConsignReturnNew/createErpConsignReturn',
    method: 'post',
    data: data
  });
}
export function updateAgentReturn(data) {
  return request({
    url: '/erpConsignReturnNew/updateErpConsignReturn',
    method: 'put',
    data: data
  });
}
export function updateAgentReturnStatus(data) {
  return request({
    url: '/erpConsignReturnNew/updateErpConsignReturnStatus',
    method: 'put',
    data: data
  });
}
export function deleteAgentReturn(data) {
  return request({
    url: '/erpConsignReturnNew/deleteErpConsignReturn',
    method: 'delete',
    data: data
  });
}
export function deleteAgentReturnByIds(data) {
  return request({
    url: '/erpConsignReturnNew/deleteErpConsignReturnByIds',
    method: 'delete',
    data: data
  });
}
export function createAgentReturnInfo(data) {
  return request({
    url: '/erpConsignReturnInfoErpNew/createErpConsignReturnInfo',
    method: 'post',
    data: data
  });
}
export function createAgentReturnInfos(data) {
  return request({
    url: '/erpConsignReturnInfoErpNew/createErpConsignReturnInfos',
    method: 'post',
    data: data
  });
}
export function updateAgentReturnInfo(data) {
  return request({
    url: '/erpConsignReturnInfoErpNew/updateErpConsignReturnInfo',
    method: 'put',
    data: data
  });
}
export function listAgentReturnInfo(params) {
  return request({
    url: '/erpConsignReturnInfoErpNew/getErpConsignReturnInfoList',
    method: 'get',
    params: params
  });
}
export function findAgentReturnInfo(params) {
  return request({
    url: '/erpConsignReturnInfoErpNew/findErpConsignReturnInfo',
    method: 'get',
    params: params
  });
}
export function deleteAgentReturnInfo(data) {
  return request({
    url: '/erpConsignReturnInfoErpNew/deleteErpConsignReturnInfo',
    method: 'delete',
    data: data
  });
}
export function deleteAgentReturnInfoByIds(data) {
  return request({
    url: '/erpConsignReturnInfoErpNew/deleteErpConsignReturnInfoByIds',
    method: 'delete',
    data: data
  });
}