var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "500px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "130px",
            "label-suffix": ":",
            model: _vm.form,
            rules: _vm.formRules,
            size: "mini"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.searchFormSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "经销商名称", prop: "manuId" } },
            [_c("span", [_vm._v(_vm._s(_vm.appRow.manuName))])]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "货款押金增加金额", prop: "productPrice" } },
            [
              _vm.action === "EDIT"
                ? _c("el-input-number", {
                    attrs: { min: 0, precision: 2, controls: false },
                    model: {
                      value: _vm.form.productPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "productPrice", $$v)
                      },
                      expression: "form.productPrice"
                    }
                  })
                : _c("span", [
                    _vm._v(_vm._s(_vm.moneyFormatter(_vm.appRow.productPrice)))
                  ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "工具押金增加金额", prop: "toolPrice" } },
            [
              _vm.action === "EDIT"
                ? _c("el-input-number", {
                    attrs: { min: 0, precision: 2, controls: false },
                    model: {
                      value: _vm.form.toolPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "toolPrice", $$v)
                      },
                      expression: "form.toolPrice"
                    }
                  })
                : _c("span", [
                    _vm._v(_vm._s(_vm.moneyFormatter(_vm.appRow.toolPrice)))
                  ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收款公司", prop: "collectionCompanyId" } },
            [
              _vm.appRow.subsidiarys.length > 0
                ? _c(
                    "div",
                    [
                      _vm.action === "EDIT"
                        ? _c(
                            "el-select",
                            {
                              attrs: { clearable: "", filterable: "" },
                              on: { change: _vm.collectionCompanyIdChange },
                              model: {
                                value: _vm.form.collectionCompanyId,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "collectionCompanyId", $$v)
                                },
                                expression: "form.collectionCompanyId"
                              }
                            },
                            _vm._l(_vm.appRow.subsidiarys, function(
                              item,
                              index
                            ) {
                              return _c("el-option", {
                                key: "collectionCompanyId" + index,
                                attrs: {
                                  label: item.companyName,
                                  value: item.ID
                                }
                              })
                            }),
                            1
                          )
                        : _c("span", [
                            _vm._v(_vm._s(_vm.appRow.collectionCompanyName))
                          ])
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _vm.action === "EDIT"
                        ? _c(
                            "el-select",
                            {
                              attrs: { clearable: "", filterable: "" },
                              on: { change: _vm.collectionCompanyIdChange },
                              model: {
                                value: _vm.form.collectionCompanyId,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "collectionCompanyId", $$v)
                                },
                                expression: "form.collectionCompanyId"
                              }
                            },
                            _vm._l(_vm.subsidiaryList, function(item, index) {
                              return _c("el-option", {
                                key: "collectionCompanyId" + index,
                                attrs: {
                                  label: item.companyName,
                                  value: item.ID
                                }
                              })
                            }),
                            1
                          )
                        : _c("span", [
                            _vm._v(_vm._s(_vm.appRow.collectionCompanyName))
                          ])
                    ],
                    1
                  )
            ]
          ),
          _c("el-form-item", { attrs: { label: "开户银行" } }, [
            _vm.action === "EDIT"
              ? _c("span", [_vm._v(_vm._s(_vm.form.collectionCompanyBank))])
              : _c("span", [_vm._v(_vm._s(_vm.appRow.collectionCompanyBank))])
          ]),
          _c("el-form-item", { attrs: { label: "银行账号" } }, [
            _vm.action === "EDIT"
              ? _c("span", [
                  _vm._v(_vm._s(_vm.form.collectionCompanyBankAccount))
                ])
              : _c("span", [
                  _vm._v(_vm._s(_vm.appRow.collectionCompanyBankAccount))
                ])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "付款方式", prop: "payType" } },
            [
              _vm.action === "EDIT"
                ? _c(
                    "el-select",
                    {
                      attrs: { clearable: "", filterable: "" },
                      model: {
                        value: _vm.form.payType,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "payType", $$v)
                        },
                        expression: "form.payType"
                      }
                    },
                    _vm._l(_vm.payTypeList, function(item, index) {
                      return _c("el-option", {
                        key: "payType" + index,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                : _c("span", [
                    _vm._v(_vm._s(_vm.payTypeFormatter(_vm.appRow.payType)))
                  ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "付款日期", prop: "receiptTime" } },
            [
              _vm.action === "EDIT"
                ? _c("el-date-picker", {
                    staticStyle: { width: "150px" },
                    attrs: {
                      type: "date",
                      placeholder: "选择日期",
                      "value-format": "yyyy-MM-dd",
                      format: "yyyy-MM-dd",
                      editable: false
                    },
                    model: {
                      value: _vm.form.receiptTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "receiptTime", $$v)
                      },
                      expression: "form.receiptTime"
                    }
                  })
                : _c("span", [_vm._v(_vm._s(_vm.appRow.receiptTime))])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "mark" } },
            [
              _vm.action === "EDIT"
                ? _c("el-input", {
                    attrs: { clearable: "", filterable: "" },
                    model: {
                      value: _vm.form.mark,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "mark", $$v)
                      },
                      expression: "form.mark"
                    }
                  })
                : _c("span", [_vm._v(_vm._s(_vm.appRow.mark))])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "付款凭证", prop: "pictureLicense" } },
            [
              _vm.action === "EDIT"
                ? _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.uploadAction,
                        headers: _vm.uploadToken,
                        "on-preview": _vm.uploadPreview,
                        "on-remove": _vm.uploadRemove,
                        "on-success": _vm.uploadSuccess,
                        "file-list": _vm.form.pictureLicense,
                        "show-file-list": true
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            type: "text",
                            icon: "el-icon-upload"
                          }
                        },
                        [_vm._v("上传附件")]
                      )
                    ],
                    1
                  )
                : _c("span", [
                    _c(
                      "div",
                      { staticClass: "image-list" },
                      _vm._l(_vm.form.pictureLicense, function(item, index) {
                        return _c(
                          "el-link",
                          {
                            key: "pictureLicense" + index,
                            attrs: { type: "primary", underline: false }
                          },
                          [
                            _c("el-image", {
                              staticStyle: { width: "60px", height: "60px" },
                              attrs: {
                                src: _vm.uploadFilePrefixURL + item.url,
                                fit: "cover",
                                "preview-src-list": _vm.uploadPreviewURL
                              }
                            })
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer", align: "center" }, slot: "footer" },
        [
          _vm.action === "EDIT"
            ? _c(
                "div",
                [
                  _c("el-button", { on: { click: _vm.dialogClose } }, [
                    _vm._v("取 消")
                  ]),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.formSubmit }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("el-button", { on: { click: _vm.dialogClose } }, [
                    _vm._v("关 闭")
                  ]),
                  _vm.form.status === 3
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.reEdit }
                        },
                        [_vm._v("重新编辑")]
                      )
                    : _vm._e()
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }