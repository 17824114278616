import { request } from '@/api/_service.js';
export function listProduct(params) {
  return request({
    url: '/productNew/getMainList',
    method: 'get',
    params: params
  });
}
export function listSpecs(params) {
  return request({
    url: '/erpProduct/getErpSpecsList',
    method: 'get',
    params: params
  });
}
export function parseBarCode(params) {
  return request({
    url: '/productNew/dipi',
    method: 'get',
    params: params
  });
}