import { request } from '@/api/_service.js'; // 更新设置

export function updateSettings(data) {
  return request({
    url: '/erpSystemErp/updateErpSystem',
    method: 'put',
    data: data
  });
} // 读取设置

export function findSettings(params) {
  return request({
    url: '/erpSystemErp/findErpSystem',
    method: 'get',
    params: params
  });
} // 保存设置

export function saveConfig(data) {
  return request({
    url: '/configErpNew/setConfig',
    method: 'post',
    data: data
  });
} // 读取设置

export function findConfig(params) {
  return request({
    url: '/configErpNew/findConfig',
    method: 'get',
    params: params
  });
}