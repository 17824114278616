import { request } from '@/api/_service.js';
export function listClientType() {
  return [{
    ID: 1,
    name: '企业'
  }, {
    ID: 2,
    name: '医院'
  }];
}
export function listConsignState() {
  return [{
    ID: null,
    name: '全部'
  }, {
    ID: 8,
    name: '暂存',
    type: 'info'
  }, {
    ID: 11,
    name: '待审核',
    type: 'primary'
  }, {
    ID: 2,
    name: '已提交',
    type: 'primary'
  }, {
    ID: 4,
    name: '已完成',
    type: 'success'
  }, {
    ID: 5,
    name: '作废',
    type: 'danger'
  }, {
    ID: 9,
    name: '驳回',
    type: 'danger'
  }, {
    ID: 10,
    name: '撤回',
    type: 'danger'
  }];
}
export function listConsignDeliveryState() {
  return [{
    ID: null,
    name: '全部'
  }, {
    ID: 2,
    name: '待发货',
    type: 'info'
  }, {
    ID: 3,
    name: '部分发货',
    type: 'warning'
  }, {
    ID: 4,
    name: '全部发货',
    type: 'success'
  }];
}
export function listReportStatus() {
  return [{
    ID: null,
    name: '全部'
  }, {
    ID: 1,
    name: '暂存',
    type: 'info'
  }, {
    ID: 2,
    name: '待审核',
    type: 'primary'
  }, {
    ID: 3,
    name: '已报价',
    type: 'success'
  }, {
    ID: 4,
    name: '已记账',
    type: 'success'
  }, {
    ID: 6,
    name: '已审核',
    type: 'success'
  }];
}
export function listGender() {
  return [{
    ID: 0,
    name: '未知'
  }, {
    ID: 1,
    name: '男'
  }, {
    ID: 2,
    name: '女'
  }];
}
export function listPayType() {
  return [{
    ID: 1,
    name: '银行转账'
  }, {
    ID: 2,
    name: '支票'
  }, {
    ID: 3,
    name: '现金'
  }];
}
export function listOptionType() {
  return [{
    ID: 1,
    name: '增加押金申请'
  }, {
    ID: 2,
    name: '退款申请'
  }];
}
export function listCashPledgeStatus() {
  return [{
    ID: 1,
    name: '暂存',
    type: 'info'
  }, {
    ID: 2,
    name: '审核中',
    type: 'warning'
  }, {
    ID: 3,
    name: '驳回',
    type: 'danger'
  }, {
    ID: 4,
    name: '通过',
    type: 'success'
  }];
}
export function listAgentReturnStatus() {
  return [{
    ID: null,
    name: '全部',
    type: 'default'
  }, {
    ID: -1,
    name: '待审核',
    type: 'primary'
  }, {
    ID: 1,
    name: '暂存',
    type: 'default'
  }, {
    ID: 2,
    name: '已提交',
    type: 'primary'
  }, {
    ID: 3,
    name: '已审核',
    type: 'warning'
  }, {
    ID: 4,
    name: '已记账',
    type: 'success'
  }, {
    ID: -2,
    name: '驳回',
    type: 'danger'
  }];
}
export function listAgentReturnSourceType() {
  return [{
    ID: null,
    name: '全部',
    type: 'default'
  }, {
    ID: 1,
    name: 'ERP',
    type: 'primary'
  }, {
    ID: 2,
    name: '寄售平台',
    type: 'warning'
  }];
}
/**
 * 列出销售订单状态
 *
 * @returns {Array} 返回销售订单状态的数组，每个元素为一个包含ID、name和type的对象
 */

export function listSellOrderState() {
  return [{
    ID: null,
    name: '全部',
    type: 'default'
  }, {
    ID: 1,
    name: '暂存',
    type: 'info'
  }, {
    ID: 2,
    name: '待发货',
    type: 'primary'
  }, {
    ID: 3,
    name: '部分发货',
    type: 'warning'
  }, {
    ID: 4,
    name: '全部发货',
    type: 'warning'
  }, {
    ID: 5,
    name: '作废',
    type: 'danger'
  }, {
    ID: 6,
    name: '结单',
    type: 'success'
  }, {
    ID: 7,
    name: '待审核',
    type: 'warning'
  }, {
    ID: 8,
    name: '暂存',
    type: 'info'
  }, {
    ID: 9,
    name: '驳回',
    type: 'danger'
  }, {
    ID: 10,
    name: '撤回',
    type: 'danger'
  }];
}