var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("工作台首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库存管理")]),
              _c("el-breadcrumb-item", [_vm._v("寄售库存管理")])
            ],
            1
          ),
          _c("div")
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "search-form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "经销商" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择", filterable: "" },
                        on: { change: _vm.loadData },
                        model: {
                          value: _vm.searchForm.manufacturerId4,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "manufacturerId4", $$v)
                          },
                          expression: "searchForm.manufacturerId4"
                        }
                      },
                      _vm._l(_vm.retailerList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "品牌" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择品牌",
                          clearable: "",
                          filterable: ""
                        },
                        on: { change: _vm.loadData },
                        model: {
                          value: _vm.searchForm.brandId,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "brandId", $$v)
                          },
                          expression: "searchForm.brandId"
                        }
                      },
                      _vm._l(_vm.brandList, function(item) {
                        return _c("el-option", {
                          key: "brandId" + item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "物资名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入物资名称", clearable: "" },
                      model: {
                        value: _vm.searchForm.productName,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "productName", $$v)
                        },
                        expression: "searchForm.productName"
                      }
                    })
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "规格型号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入规格型号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.specificationModel,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.searchForm,
                                "specificationModel",
                                $$v
                              )
                            },
                            expression: "searchForm.specificationModel"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品编号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品编号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.productNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "productNumber", $$v)
                            },
                            expression: "searchForm.productNumber"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品批号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品批号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.batch_number,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "batch_number", $$v)
                            },
                            expression: "searchForm.batch_number"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "序列号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入序列号", clearable: "" },
                          model: {
                            value: _vm.searchForm.trackingCode,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "trackingCode", $$v)
                            },
                            expression: "searchForm.trackingCode"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品主码" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品主码",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.barCode,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "barCode", $$v)
                            },
                            expression: "searchForm.barCode"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { "false-label": 0, "true-label": 1 },
                        model: {
                          value: _vm.searchForm.showEmpty,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "showEmpty", $$v)
                          },
                          expression: "searchForm.showEmpty"
                        }
                      },
                      [_vm._v("不显示0库存物资")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.searchSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c("el-button", { on: { click: _vm.dataExport } }, [
                      _vm._v("导出")
                    ]),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dataLoading,
              expression: "dataLoading"
            }
          ],
          ref: "dataTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.searchForm,
            columns: _vm.columns,
            data: _vm.dataList,
            cacheName: _vm.dataTableCacheName
          },
          scopedSlots: _vm._u([
            {
              key: "url",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.url
                    ? _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.toPreview(row.url)
                            }
                          }
                        },
                        [_vm._v("点击查看")]
                      )
                    : _c("span", { staticClass: "text-danger" }, [
                        _vm._v("未上传")
                      ])
                ]
              }
            }
          ])
        }),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
                align: "center",
                total: _vm.searchForm.total,
                "page-sizes": [5, 10, 15, 20, 30, 40, 50, 100],
                "current-page": _vm.searchForm.page,
                "page-size": _vm.searchForm.pageSize
              },
              on: {
                "current-change": _vm.pageChange,
                "size-change": _vm.sizeChange
              }
            })
          ],
          1
        ),
        _vm.showViewer
          ? _c("el-image-viewer", {
              attrs: {
                "on-close": _vm.closeViewer,
                "url-list": _vm.previewImgURL
              }
            })
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }