import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2966cad1&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=2966cad1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2966cad1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\wwwroot\\有台科技供应链协同平台\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2966cad1')) {
      api.createRecord('2966cad1', component.options)
    } else {
      api.reload('2966cad1', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=2966cad1&scoped=true&", function () {
      api.rerender('2966cad1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/invoice/index.vue"
export default component.exports