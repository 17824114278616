import _objectSpread from "D:/wwwroot/\u6709\u53F0\u79D1\u6280\u4F9B\u5E94\u94FE\u534F\u540C\u5E73\u53F0/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';
export default {
  computed: _objectSpread({}, mapState('d2admin/fullscreen', ['active'])),
  methods: _objectSpread({}, mapActions('d2admin/fullscreen', ['toggle']))
};