var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              { staticStyle: { "margin-bottom": "20px" }, attrs: { span: 24 } },
              [
                _c("el-card", { attrs: { shadow: "never" } }, [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("快捷入口")])
                  ]),
                  _c("div", { staticStyle: { padding: "20px 10px" } }, [
                    _c(
                      "div",
                      { staticClass: "list" },
                      _vm._l(_vm.shortcutsList, function(item2, index2) {
                        return _c(
                          "el-link",
                          {
                            key: index2,
                            staticClass: "item",
                            attrs: { underline: false },
                            on: {
                              click: function($event) {
                                return _vm.navClick(item2.path)
                              }
                            }
                          },
                          [
                            item2.icon.indexOf(".svg") >= 0
                              ? _c("d2-icon-svg", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                    padding: "30px",
                                    color: "#404243",
                                    "border-radius": "5px"
                                  },
                                  style: {
                                    background: _vm.getBackgroundColor()
                                  },
                                  attrs: {
                                    name: item2.icon.substring(
                                      0,
                                      item2.icon.indexOf(".svg")
                                    )
                                  }
                                })
                              : _c("i", {
                                  class: "fa fa-" + item2.icon,
                                  style: {
                                    background: _vm.getBackgroundColor()
                                  },
                                  attrs: { "aria-hidden": "true" }
                                }),
                            _c("div", { staticClass: "text" }, [
                              _vm._v(_vm._s(item2.title))
                            ])
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ])
                ])
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 12 } },
              [
                _c("el-card", { attrs: { shadow: "never" } }, [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("工作待办")])
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.todoList, size: "small" }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "title",
                              label: "待办事项",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "time",
                              width: "200",
                              label: "时间",
                              align: "center"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 12 } },
              [
                _c("el-card", { attrs: { shadow: "never" } }, [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("公告栏")])
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.announceList, size: "small" }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "type",
                              width: "100",
                              label: "类型",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "title",
                              label: "标题",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "time",
                              width: "200",
                              label: "时间",
                              align: "center"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }