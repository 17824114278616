import _objectSpread from "D:/wwwroot/\u6709\u53F0\u79D1\u6280\u4F9B\u5E94\u94FE\u534F\u540C\u5E73\u53F0/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import layoutHeaderAside from '@/layout/header-aside';

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/stock',
  name: 'stock',
  meta: meta,
  component: layoutHeaderAside,
  children: [{
    path: 'detail/consign',
    name: 'stock-detail-consign-index',
    component: _import('stock/detail/consign.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售库存管理'
    }),
    props: true
  }]
};