import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { downloadExcelTemplate } from '@/api/report.js';
import util from '@/libs/util.js';
import axios from 'axios';
import format from '@/libs/util.format';
import dayjs from 'dayjs';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: 'YTLMS报台单导入',
      dialogVisible: false,
      form: {
        page: 1,
        pageSize: 10,
        total: 0
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init() {
      var that = this;
      that.dialogVisible = true;
      that.errorMsg = null;
      that.successMsg = null;
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    detailListFormat: function detailListFormat(rows) {
      var that = this;
      var resultRows = [];
      rows.forEach(function (row, index) {
        resultRows.push({
          id: null,
          ID: row.ID,
          rowIndex: index,
          productNumber: row.specs.productNumber,
          productName: row.main.productName,
          specificationModel: row.specs.specificationModel,
          stockQuantity: row.stock_quantity,
          normalQuantity: row.normalQuantity,
          consumedQuantity: row.consumedQuantity,
          receiptQuantity: row.receiptQuantity,
          issueLocking: row.issueLocking,
          salesPendingApproval: row.salesPendingApproval,
          productPackageLocking: row.productPackageLocking,
          batchNumber: row.batch_number,
          manufacturerDate: row.manufacturer_date,
          validityDate: row.validity_date,
          sterilizationBatchNumber: row.sterilization_batch_number,
          sterilizationDate: row.sterilization_date,
          trackingCode: row.trackingCode,
          medCode: row.specs.medCode,
          unit: row.specs.unit,
          packNum: row.specs.packNum,
          packUnit: row.specs.packUnit,
          unitPrice: row.specs.wholesalePrice,
          registrationNumber: row.main.registrationNumber,
          productPlace: row.main.productPlace,
          brandName: row.main.brandName,
          wholesalePurchasePrice: row.specs.wholesalePurchasePrice,
          consignmentPurchasePrice: row.specs.consignmentPurchasePrice,
          marketPrice: row.specs.marketPrice,
          consignmentPrice: row.specs.consignmentPrice,
          wholesalePrice: row.specs.wholesalePrice,
          expirationDate: row.main.expirationDate,
          mainId: row.main_id,
          specsId: row.specs_id,
          assemblyId: 0,
          consumTypeId: 0,
          deliveryId: 0,
          inventoryId: row.ID,
          deliveryQuantity: row.deliveryQuantity,
          quantityBacak: 0,
          quantityUsed: 0,
          manufacturer: row.main.manufacturer,
          manufacturerName: row.manufacturerName,
          checked: true,
          isExpire: that.isExpire(row.validity_date),
          returnedQuantity: 0,
          expireDays: that.expireDays(row.validity_date),
          sellQuantity: row.excelDeliveryQuantity
        });
      });
      return resultRows;
    },
    pageChange: function pageChange(page) {
      var that = this;
      that.form.page = page;
      that.loadData();
    },
    sizeChange: function sizeChange(pageSize) {
      var that = this;
      that.form.page = 1;
      that.form.pageSize = pageSize;
      that.loadData();
    }
  }
};