import _objectSpread from "D:/wwwroot/\u6709\u53F0\u79D1\u6280\u4F9B\u5E94\u94FE\u534F\u540C\u5E73\u53F0/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _createForOfIteratorHelper from "D:/wwwroot/\u6709\u53F0\u79D1\u6280\u4F9B\u5E94\u94FE\u534F\u540C\u5E73\u53F0/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import util from '@/libs/util';
Vue.use(VueI18n);

function loadLocaleMessages() {
  var locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

  var messages = {};

  var _iterator = _createForOfIteratorHelper(locales.keys()),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var key = _step.value;
      var matched = key.match(/([A-Za-z0-9-_]+)\./i);

      if (matched && matched.length > 1) {
        var locale = matched[1];

        var localeElementUI = require("element-ui/lib/locale/lang/".concat(locales(key)._element));

        messages[locale] = _objectSpread(_objectSpread({}, locales(key)), localeElementUI ? localeElementUI.default : {});
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return messages;
}

var messages = loadLocaleMessages();
Vue.prototype.$languages = Object.keys(messages).map(function (langlage) {
  return {
    label: messages[langlage]._name,
    value: langlage
  };
});
var i18n = new VueI18n({
  locale: util.cookies.get('lang') || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: messages
});
export default i18n;