var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "800px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            margin: "20px 0",
            "text-align": "right",
            position: "absolute",
            right: "20px",
            top: "30px"
          }
        },
        [
          _c(
            "el-popover",
            {
              ref: "optionsPopover",
              attrs: { placement: "bottom", trigger: "click" }
            },
            [
              _c("TableHeadComponent", {
                ref: "TableHeadComponentRef",
                attrs: {
                  "cache-name": _vm.cacheName,
                  "remote-columns-list": _vm.remoteColumnsList
                },
                on: { "save-table-set": _vm.saveTableSet }
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { slot: "reference", type: "primary", size: "mini" },
                  slot: "reference"
                },
                [_vm._v("字段自定义")]
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.printClick }
            },
            [_vm._v("点击打印")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "mini",
                icon: "el-icon-download"
              },
              on: { click: _vm.exportExcel }
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            "element-loading-text": "拼命加载中...",
            "element-loading-spinner": "el-icon-loading"
          }
        },
        [
          _vm.loading === false
            ? _c(
                "div",
                {
                  ref: "printContainerConsign",
                  staticStyle: { width: "100%" },
                  attrs: { id: "printContainerConsign", loading: _vm.loading }
                },
                [
                  _c("div", { staticStyle: { position: "relative" } }, [
                    _c("h3", [_vm._v(_vm._s(_vm.profile.organizationName))]),
                    _c("h3", [_vm._v("寄售发货单")]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          top: "0",
                          right: "0"
                        }
                      },
                      [_c("img", { attrs: { id: "barcode" } })]
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-wrap": "wrap",
                        "font-size": "12px",
                        "margin-top": "20px",
                        color: "#000"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("经销商：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderRow.distributorName))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("订单编号：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderRow.orderNumber))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("供应商：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderRow.organization.name))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("收件人：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderRow.receiveName))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("联系方式：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderRow.contactInfo))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("收货地址：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderRow.receiveAddress))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "100%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("备注：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderRow.remarks))
                          ])
                        ]
                      )
                    ]
                  ),
                  _c("div", { staticClass: "detail-table" }, [
                    _c("table", [
                      _c("thead", [
                        _c(
                          "tr",
                          [
                            _vm._l(_vm.remoteColumnsList, function(
                              item,
                              index
                            ) {
                              return [
                                item.checked === true
                                  ? _c("th", { key: "field-" + index }, [
                                      _vm._v(_vm._s(item.label))
                                    ])
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(_vm.detailList, function(item, index) {
                            return _c(
                              "tr",
                              { key: "data-col-" + index },
                              [
                                _vm._l(_vm.remoteColumnsList, function(
                                  item2,
                                  index2
                                ) {
                                  return [
                                    item2.checked === true
                                      ? _c(
                                          "td",
                                          { key: "data-item-" + index2 },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dataFormatter(
                                                  item[item2.prop],
                                                  item2.type
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          }),
                          _vm._l(_vm.getSummary(), function(item, index) {
                            return _c(
                              "tr",
                              { key: index },
                              [
                                _vm._l(_vm.remoteColumnsList, function(
                                  item2,
                                  index2
                                ) {
                                  return [
                                    item2.checked === true
                                      ? _c(
                                          "td",
                                          {
                                            key: "summary-" + index2,
                                            staticClass: "summary"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dataFormatter(
                                                  item[item2.prop],
                                                  item2.type
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          })
                        ],
                        2
                      )
                    ])
                  ])
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }