import { request } from '@/api/_service.js';
export function listReportOrder(params) {
  return request({
    url: '/tradeOrderErpNew/getErpTradeOrderList',
    method: 'get',
    params: params
  });
}
export function listReportOrderExport(params) {
  return request({
    url: '/tradeOrderErpNew/getErpTradeOrderList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findReportOrder(params) {
  return request({
    url: '/tradeOrderErpNew/findErpTradeOrder',
    method: 'get',
    params: params
  });
}
export function createReportOrder(data) {
  return request({
    url: '/tradeOrderErpNew/createErpTradeOrder ',
    method: 'post',
    data: data
  });
}
export function updateReportOrder(data) {
  return request({
    url: '/tradeOrderErpNew/updateErpTradeOrder',
    method: 'put',
    data: data
  });
}
export function updateReportOrderState(data) {
  return request({
    url: '/tradeOrderErpNew/updateErpTradeOrderStatus',
    method: 'put',
    data: data
  });
}
export function deleteReportOrder(data) {
  return request({
    url: '/tradeOrderErpNew/deleteErpTradeOrder',
    method: 'delete',
    data: data
  });
}
export function listReportOrderDetail(params) {
  return request({
    url: '/tradeDetailErpNew/getErpTradeDetailList',
    method: 'get',
    params: params
  });
}
export function createReportOrderDetails(data) {
  return request({
    url: '/tradeDetailErpNew/createErpTradeDetail',
    method: 'post',
    data: data
  });
}
export function updateReportOrderDetail(data) {
  return request({
    url: '/tradeDetailErpNew/updateErpTradeDetail',
    method: 'put',
    data: data
  });
}
export function findReportOrderDetail(params) {
  return request({
    url: '/tradeDetailErpNew/findErpTradeDetail',
    method: 'get',
    params: params
  });
}
export function deleteReportOrderDetail(data) {
  return request({
    url: '/tradeDetailErpNew/deleteErpTradeDetail',
    method: 'delete',
    data: data
  });
}
export function deleteReportOrderDetailByIds(data) {
  return request({
    url: '/tradeDetailErpNew/deleteErpTradeDetailByIds',
    method: 'delete',
    data: data
  });
}
export function downloadExcelTemplate(params) {
  return request({
    url: '/erpConsignReturnInfoErpNew/getErpConsignDetailTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}