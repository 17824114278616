var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("订货管理")]),
              _c("el-breadcrumb-item", [_vm._v("寄售退货单")]),
              _c("el-breadcrumb-item", [
                _vm._v(
                  _vm._s(_vm.form.id ? "寄售退货单详情" : "新增寄售退货单")
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "font-size": "14px", color: "#606266" } },
            [
              _vm.orderInfo.orderNumber
                ? _c("span", [
                    _vm._v("退货单号：" + _vm._s(_vm.orderInfo.orderNumber))
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "120px",
              "label-suffix": ":",
              "show-message": false,
              disabled: _vm.form.status > 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户名称", prop: "manufacturerId" } },
                      [
                        _vm.form.status === 1
                          ? _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择"
                                },
                                on: { change: _vm.manufacturerIdChange },
                                model: {
                                  value: _vm.form.manufacturerId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "manufacturerId", $$v)
                                  },
                                  expression: "form.manufacturerId"
                                }
                              },
                              _vm._l(_vm.manufacturerList, function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: "manufacturerId" + index,
                                  attrs: {
                                    label: item.name,
                                    value: item.ID,
                                    disabled: !item.show_status
                                  }
                                })
                              }),
                              1
                            )
                          : _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.manufacturerName))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退回仓库", prop: "warehouseId" } },
                      [
                        _vm.form.status === 1
                          ? _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: _vm.form.warehouseId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "warehouseId", $$v)
                                  },
                                  expression: "form.warehouseId"
                                }
                              },
                              _vm._l(
                                _vm.warehouseList.filter(function(item) {
                                  return item.isBa === 1
                                }),
                                function(item, index) {
                                  return _c("el-option", {
                                    key: "warehouseId" + index,
                                    attrs: {
                                      label: item.name,
                                      value: item.ID,
                                      disabled: item.status === 0
                                    }
                                  })
                                }
                              ),
                              1
                            )
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.warehouseName))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退货人", prop: "returnedBy" } },
                      [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.returnedBy))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退货日期", prop: "returnDate" } },
                      [
                        _vm.form.status === 1
                          ? _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                placeholder: "退货时间",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd"
                              },
                              model: {
                                value: _vm.form.returnDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "returnDate", $$v)
                                },
                                expression: "form.returnDate"
                              }
                            })
                          : _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.returnDate))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 16, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _vm.form.status === 1
                          ? _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "备注",
                                  clearable: "",
                                  maxlength: "500"
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-edit el-input__icon",
                                  attrs: { slot: "suffix" },
                                  slot: "suffix"
                                })
                              ]
                            )
                          : _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.remark))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c("div", [
              _c(
                "div",
                [
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-plus"
                          },
                          on: { click: _vm.toSelectProduct }
                        },
                        [_vm._v("增加产品")]
                      )
                    : _vm._e(),
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.toScanInput()
                            }
                          }
                        },
                        [_vm._v("扫码录入")]
                      )
                    : _vm._e(),
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-upload2"
                          },
                          on: { click: _vm.toImportFromExcel }
                        },
                        [_vm._v("Excel产品明细导入")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "14px",
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center",
                  color: "#606266"
                }
              },
              [
                _c("div", [
                  _vm._v(" 退货总数："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.returnSumQuantity()))
                  ])
                ]),
                _c("div", { staticStyle: { margin: "0 20px" } }, [
                  _vm._v(" 退货总金额："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.returnSumAmount()))
                  ])
                ])
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showScanInputBox,
                expression: "showScanInputBox"
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  margin: "10px 0",
                  display: "flex",
                  "align-items": "center"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "450px" } },
                  [
                    _c("el-input", {
                      ref: "scanInputBoxRef",
                      staticClass: "bar-input",
                      attrs: { placeholder: "请扫描产品条码", clearable: "" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.barCodeSearch.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.scanBarCode,
                        callback: function($$v) {
                          _vm.scanBarCode = $$v
                        },
                        expression: "scanBarCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#f00",
                      flex: "1",
                      "margin-left": "20px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.tipMessage))]
                )
              ]
            )
          ]
        ),
        _c(
          "el-form",
          {
            ref: "detailForm",
            attrs: {
              model: _vm.detailForm,
              rules: _vm.detailFormRules,
              size: "mini",
              "show-message": false
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c("YTable", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.detailLoading,
                  expression: "detailLoading"
                }
              ],
              key: "detailListTable" + _vm.timestamp,
              ref: "dragTableRef",
              attrs: {
                hasPagination: true,
                hasIndex: true,
                cacheName: _vm.$route.name,
                columns: _vm.columns,
                data: _vm.detailForm.data.slice(
                  (_vm.detailForm.page - 1) * _vm.detailForm.pageSize,
                  _vm.detailForm.page * _vm.detailForm.pageSize
                ),
                pagination: _vm.detailForm,
                rowClassName: _vm.rowClassName
              },
              on: { "row-click": _vm.detailRowClick },
              scopedSlots: _vm._u([
                {
                  key: "returnQuantity",
                  fn: function(scope) {
                    return [
                      _vm.form.status === 1
                        ? _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              min: 1,
                              max: scope.row.normalQuantity,
                              controls: false
                            },
                            on: {
                              change: function($event) {
                                return _vm.toSaveOne(scope.row)
                              }
                            },
                            model: {
                              value: scope.row.returnQuantity,
                              callback: function($$v) {
                                _vm.$set(scope.row, "returnQuantity", $$v)
                              },
                              expression: "scope.row.returnQuantity"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(scope.row.returnQuantity))])
                    ]
                  }
                },
                {
                  key: "remark",
                  fn: function(scope) {
                    return [
                      _vm.form.status === 1
                        ? _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { size: "mini" },
                            on: {
                              change: function($event) {
                                return _vm.toSaveOne(scope.row)
                              }
                            },
                            model: {
                              value: scope.row.remark,
                              callback: function($$v) {
                                _vm.$set(scope.row, "remark", $$v)
                              },
                              expression: "scope.row.remark"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(scope.row.remark))])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" } },
                  [
                    _vm.form.status === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              disabled: _vm.detailRowIndex < 0
                            },
                            on: { click: _vm.toRowRemove }
                          },
                          [_vm._v("删除行")]
                        )
                      : _vm._e(),
                    _vm.form.status == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: { click: _vm.toFormStorage }
                          },
                          [_vm._v("暂存")]
                        )
                      : _vm._e(),
                    _vm.form.status === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.toFormSubmit }
                          },
                          [_vm._v("提交")]
                        )
                      : _vm._e(),
                    _vm.form.status === -1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: { click: _vm.toUnFormSubmit }
                          },
                          [_vm._v("撤销提交")]
                        )
                      : _vm._e(),
                    _vm.form.status === 1 && _vm.form.id
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.toOrderDelete }
                          },
                          [_vm._v("删除订单")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "prevent-re-click",
                            rawName: "v-prevent-re-click"
                          }
                        ],
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.toOrderPrint }
                      },
                      [_vm._v("打印")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("ExcelImportComponent", {
        ref: "ExcelImportComponentRef",
        on: { add: _vm.stockAddToList }
      }),
      _c("SelectProductComponent", {
        ref: "SelectProductComponentRef",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("PrintComponent", { ref: "PrintComponentRef" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }