var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        visible: _vm.drawerVisible,
        direction: "rtl",
        size: "70%",
        "before-close": _vm.drawerClose
      },
      on: {
        "update:visible": function($event) {
          _vm.drawerVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            height: "100%",
            display: "flex",
            "flex-direction": "column",
            "justify-content": "space-between",
            padding: "0 20px 20px 20px"
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "searchForm",
                      attrs: {
                        model: _vm.searchForm,
                        inline: true,
                        size: "small",
                        "label-suffix": ":"
                      },
                      nativeOn: {
                        submit: function($event) {
                          $event.preventDefault()
                        },
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchFormSubmit.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品主码" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.searchForm.barCode,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "barCode", $$v)
                              },
                              expression: "searchForm.barCode"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品编码" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.searchForm.productNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "productNumber", $$v)
                              },
                              expression: "searchForm.productNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品名称" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.searchForm.productName,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "productName", $$v)
                              },
                              expression: "searchForm.productName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchFormExpand,
                              expression: "searchFormExpand"
                            }
                          ],
                          attrs: { label: "规格型号" }
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.searchForm.specificationModel,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.searchForm,
                                  "specificationModel",
                                  $$v
                                )
                              },
                              expression: "searchForm.specificationModel"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchFormExpand,
                              expression: "searchFormExpand"
                            }
                          ],
                          attrs: { label: "产品品牌" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.brandLoading,
                                  expression: "brandLoading"
                                }
                              ],
                              attrs: { clearable: "", filterable: "" },
                              model: {
                                value: _vm.searchForm.brandId,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchForm, "brandId", $$v)
                                },
                                expression: "searchForm.brandId"
                              }
                            },
                            _vm._l(_vm.brandList, function(item, index) {
                              return _c("el-option", {
                                key: "brandId" + index,
                                attrs: {
                                  label: item.name,
                                  value: item.ID,
                                  disabled: !item.showStatus
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-right": "10px" },
                              attrs: { type: "primary" },
                              on: { click: _vm.searchFormSubmit }
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary", underline: false },
                              on: {
                                click: function($event) {
                                  _vm.searchFormExpand = !_vm.searchFormExpand
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.searchFormExpand ? "合并" : "展开")
                              ),
                              _c("i", {
                                class:
                                  "el-icon-arrow-" +
                                  (_vm.searchFormExpand ? "up" : "down") +
                                  " el-icon--right"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dataLoading,
                      expression: "dataLoading"
                    }
                  ],
                  ref: "productTableRef",
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    "highlight-current-row": "",
                    data: _vm.dataList,
                    size: "small",
                    "row-class-name": _vm.rowClassName,
                    "header-cell-style": {
                      background: "#f5f7fa",
                      borderColor: "#ebeef5",
                      color: "#333"
                    }
                  },
                  on: {
                    "row-click": _vm.rowClick,
                    "selection-change": _vm.selectionChange
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "#",
                      type: "index",
                      width: "50",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var $index = ref.$index
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (_vm.searchForm.page - 1) *
                                    _vm.searchForm.pageSize +
                                    $index +
                                    1
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "50", align: "center" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编号",
                      width: "100",
                      prop: "productNumber",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      "min-width": "150",
                      prop: "productName",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格型号",
                      width: "150",
                      prop: "specificationModel",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "单位",
                      width: "80",
                      prop: "unit",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "品牌",
                      width: "100",
                      prop: "brandName",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "批发价",
                      width: "100",
                      prop: "wholesalePrice",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _vm.consignmentPriceShow
                    ? _c("el-table-column", {
                        attrs: {
                          label: "寄售价",
                          width: "100",
                          prop: "consignmentPrice",
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      label: "注册/备案证",
                      "min-width": "150",
                      prop: "registrationNumber",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "厂家",
                      "min-width": "150",
                      prop: "manufacturer",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购数量",
                      width: "100",
                      prop: "sellQuantity",
                      align: "center",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("el-input-number", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                precision: 0,
                                controls: false,
                                size: "mini",
                                min: 0,
                                disabled: !row.checked
                              },
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              },
                              model: {
                                value: row.sellQuantity,
                                callback: function($$v) {
                                  _vm.$set(row, "sellQuantity", $$v)
                                },
                                expression: "row.sellQuantity"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { margin: "10px 0" } },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      align: "center",
                      total: _vm.searchForm.total,
                      "page-sizes": [10, 15, 20, 30, 40, 50, 100],
                      "current-page": _vm.searchForm.page,
                      "page-size": _vm.searchForm.pageSize
                    },
                    on: {
                      "current-change": _vm.pageChange,
                      "size-change": _vm.sizeChange
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { align: "center" } },
            [
              _c("el-button", { on: { click: _vm.drawerClose } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.formSubmit } },
                [_vm._v("添 加")]
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }