import { request } from '@/api/_service.js';
export function bindDealer(data) {
  return request({
    url: '/erp/bind_dealer',
    method: 'post',
    data: data
  });
}
export function listCompany(params) {
  return request({
    url: '/companyNew/getCompanyList',
    method: 'get',
    params: params
  });
}