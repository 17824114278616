import { request } from '@/api/_service.js';
export function listCustom(params) {
  return request({
    url: '/dealerClientErp/getErpDealerClientList',
    method: 'get',
    params: params
  });
}
export function findCustom(params) {
  return request({
    url: '/dealerClientErp/findErpDealerClient',
    method: 'get',
    params: params
  });
}
export function createCustom(data) {
  return request({
    url: '/dealerClientErp/createErpDealerClient',
    method: 'post',
    data: data
  });
}
export function updateCustom(data) {
  return request({
    url: '/dealerClientErp/updateErpDealerClient',
    method: 'put',
    data: data
  });
}
export function deleteCustom(data) {
  return request({
    url: '/dealerClientErp/deleteErpDealerClientByIds',
    method: 'delete',
    data: data
  });
}