import { find, assign } from 'lodash';
import faker from 'faker/locale/zh_CN';
import { service, serviceForMock, request, requestForMock, mock } from '@/api/_service.js';
import * as tools from '@/api/_tools.js';
/** 为侧边栏增加的代码开始  **/

import util from '@/libs/util';
import router from '@/router';

var toLogin = function toLogin() {
  util.cookies.remove('token');
  util.cookies.remove('uuid'); // 清空 vuex 用户信息
  // this.$store.dispatch('d2admin/user/set', {}, { root: true })

  router.push({
    name: 'login'
  });
};
/** 为侧边栏增加的代码结束  **/
// const users = [
//   { username: 'admin', password: 'admin', uuid: 'admin-uuid', name: 'Admin' },
//   { username: 'editor', password: 'editor', uuid: 'editor-uuid', name: 'Editor' },
//   { username: 'user1', password: 'user1', uuid: 'user1-uuid', name: 'User1' }
// ]

/**
 * @description 登录
 * @param {Object} data 登录携带的信息
 */


export function SYS_USER_LOGIN() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/erp/dealer_user_login',
    method: 'post',
    data: data
  });
}
export function SYS_USER_LOGIN_BY_MOBILE(data) {
  return request({
    url: '/erp/dealer_user_login',
    method: 'post',
    data: data
  });
}