var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tooltip",
        { attrs: { effect: "dark", content: "通知", placement: "bottom" } },
        [
          _c(
            "el-popover",
            { attrs: { placement: "bottom", width: "600", trigger: "click" } },
            [
              _c(
                "div",
                { staticClass: "order-list" },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.notifyList,
                        "max-height": "300",
                        size: "mini"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "sponsorName",
                          label: "来自",
                          width: "80"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "CreatedAt",
                          width: "150",
                          label: "时间",
                          formatter: function(row) {
                            return _vm.timeFormatter(row.CreatedAt)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "d2-mr btn-text can-hover",
                  staticStyle: { "margin-right": "20px" },
                  attrs: { slot: "reference", type: "text" },
                  slot: "reference"
                },
                [
                  _c("d2-icon", {
                    staticStyle: { "font-size": "16px" },
                    attrs: { name: "bell-o" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }