import Vue from 'vue'; // 在vue上挂载一个指量 preventReClick
// vue防止按钮重复点击

var preventReClick = Vue.directive('preventReClick', {
  inserted: function inserted(el, binding) {
    el.addEventListener('click', function () {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(function () {
          el.disabled = false; // 设置时间
        }, binding.value || 1000);
      }
    });
  }
});
export { preventReClick };