var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "690px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": true,
        "append-to-body": true,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-carousel",
            { attrs: { "indicator-position": "outside", height: "380px" } },
            _vm._l(_vm.imgList, function(item) {
              return _c(
                "el-carousel-item",
                { key: item },
                [
                  _c("el-image", {
                    staticStyle: { width: "650px" },
                    attrs: { src: "./image/" + item, fit: "contain" }
                  })
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("h2", { attrs: { align: "center" } }, [
        _vm._v("欢迎留下联系方式，我们会尽快与您取得联系！")
      ]),
      _c(
        "div",
        { staticStyle: { width: "400px", margin: "auto" } },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                size: "small",
                "label-width": "100px",
                "label-suffix": ":"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                },
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.formSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司名称", prop: "manuId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": ""
                      },
                      model: {
                        value: _vm.form.manuId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "manuId", $$v)
                        },
                        expression: "form.manuId"
                      }
                    },
                    _vm._l(_vm.distributorList, function(item, index) {
                      return _c("el-option", {
                        key: "manuId" + index,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "您的称呼", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", clearable: "" },
                    model: {
                      value: _vm.form.userName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "userName", $$v)
                      },
                      expression: "form.userName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "您的手机号", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "11", clearable: "" },
                    model: {
                      value: _vm.form.mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "mobile", $$v)
                      },
                      expression: "form.mobile"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "mark" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "200", clearable: "" },
                    model: {
                      value: _vm.form.mark,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "mark", $$v)
                      },
                      expression: "form.mark"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "big1" },
                      on: { click: _vm.formSubmit }
                    },
                    [_vm._v("提交申请")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }